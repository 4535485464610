import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, ModalBody, ModalContent, ModalOverlay, Stack, SimpleGrid, Card, CardBody, useColorMode, Box, StackDivider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, useDisclosure, Text, Heading } from "@chakra-ui/react";
import { useMutation, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { deleteVehicle, getVehicles } from "../services";
import { Vehicle } from "../models";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { VehicleCreator } from "./VehicleCreator";
import { GLOBAL_NAMESPACE } from "index";
import { VehicleLineCard } from "app/shared";
import { ReactComponent as PlusIcon } from "app/shared/icons/Plus.svg";
import { ReactComponent as ArrowRightIcon } from "app/shared/icons/ArrowRight.svg";

interface Props {
  isScrollBottom: boolean;
  onVehicleSelect?: (vehicle: Vehicle) => void;
  deleteDisabled?: boolean;
}

export const VehiclesList: React.FC<Props> = ({ isScrollBottom, onVehicleSelect, deleteDisabled }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();
  const [showVehicleCreateModal, setShowVehicleCreateModal] = useState<boolean>(false);
  const deleteDialog = useDisclosure();
  const deleteDialogCancelRef = useRef<HTMLButtonElement>(null);

  const query = useInfiniteQuery({
    queryKey: ["vehicles"],
    queryFn: (data) => getVehicles(data.pageParam ?? 0),
    getNextPageParam: (lastPage) => lastPage.links.next ? lastPage.meta.page + 1 : undefined
  });
  const deleteMutation = useMutation<void, AxiosError<ResponseError>, string>({
    mutationFn: (vehicleId) => deleteVehicle(vehicleId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
      deleteDialog.onClose();
    }
  });

  const selectVehicle = (vehicle: Vehicle) => {
    onVehicleSelect?.(vehicle);
    setSelectedVehicle(vehicle);
  }

  const deleteSelectedVehicle = () => {
    if (selectedVehicle) {
      deleteMutation.mutate(selectedVehicle.id);
    }
  }

  useEffect(() => {
    if (isScrollBottom) {
      if (query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollBottom]);

  return (
    <Box>
      <Stack divider={<StackDivider />} spacing={4}>
        <SimpleGrid spacing={4}>
          {query.data?.pages?.map((group, key) =>
            <React.Fragment key={key}>
              {group.data?.map(vehicle => {
                const isSelected = onVehicleSelect ? vehicle.id === selectedVehicle?.id : false;
                const isDeleteDisabled = deleteDisabled || !vehicle.deletable;

                return (
                  <Card
                    key={vehicle.id}
                    borderWidth={1}
                    borderColor={isSelected ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}>
                    <CardBody>
                      <VehicleLineCard
                        vehicle={vehicle}
                        onClick={onVehicleSelect ? () => selectVehicle(vehicle) : undefined}
                        onDelete={isDeleteDisabled ? undefined : () => { selectVehicle(vehicle); deleteDialog.onOpen(); }}
                      />
                    </CardBody>
                  </Card>
                );
              })}
            </React.Fragment>
          )}
          {query.isFetchingNextPage &&
            <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>
          }
        </SimpleGrid>
        <Stack direction="column">
          <Button
            variant="outline"
            width="fit-content"
            leftIcon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><PlusIcon /></Box>}
            onClick={() => setShowVehicleCreateModal(true)}>
            {t("vehiclesList.addVehicle")}
          </Button>
        </Stack>
      </Stack>
      <Modal isOpen={showVehicleCreateModal} onClose={() => setShowVehicleCreateModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <VehicleCreator
              onVehicleCreate={vehicle => {
                selectVehicle(vehicle);
                setShowVehicleCreateModal(false);
              }}
              close={() => setShowVehicleCreateModal(false)}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={deleteDialog.isOpen}
        leastDestructiveRef={deleteDialogCancelRef}
        onClose={deleteDialog.onClose}
        size="xs"
        variant="center">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Heading variant="h3Strong" colorScheme="negative">{t("vehiclesList.deleteDialogHeader")}</Heading>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text variant="h5Regular">{t("vehiclesList.deleteDialogDescription")}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant="negative"
                rightIcon={<ArrowRightIcon />}
                iconSpacing="auto"
                width="100%"
                onClick={() => deleteSelectedVehicle()}>
                {t("vehiclesList.deleteDialogConfirmation")}
              </Button>
              <Button
                ref={deleteDialogCancelRef}
                variant="outline"
                width="100%"
                mt={3}
                justifyContent="start"
                onClick={deleteDialog.onClose}>
                {t("vehiclesList.deleteDialogCancel")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
