import React, { useState } from "react";
import { Card, CardBody, Heading, IconButton, Input, InputGroup, InputRightElement, Text, useColorMode } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createVehicle, updateVehicle } from "../services";
import { Vehicle } from "../models";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { GLOBAL_NAMESPACE } from "index";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";
import { ReactComponent as CorrectIcon } from "app/shared/icons/Correct.svg";
import { ReactComponent as SearchIcon } from "app/shared/icons/Search.svg";
import { Page, VehicleLineCard } from "app/shared";

export interface UpdateVehicleCommand {
  vehicleId: string;
  status: "Active";
};

interface Props {
  onVehicleCreate: (vehicle: Vehicle) => void;
  close: () => void;
}

export const VehicleCreator: React.FC<Props> = ({ onVehicleCreate, close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();

  const [inputValue, setInputValue] = useState<string>("");
  const [createdVehicle, setCreatedVehicle] = useState<Vehicle | undefined>();

  const createVehicleMutation = useMutation<Vehicle, AxiosError<ResponseError>, string>({
    mutationFn: (plateNumber) => createVehicle(plateNumber),
    onSuccess: (vehicle) => setCreatedVehicle(vehicle)
  });
  const updateVehicleMutation = useMutation<Vehicle, AxiosError<ResponseError>, UpdateVehicleCommand>({
    mutationFn: (data) => updateVehicle(data.vehicleId, data.status),
    onSuccess: (vehicle) => {
      queryClient.invalidateQueries({ queryKey: ["vehicles"] });
      onVehicleCreate(vehicle);
    }
  });

  const onSearchClick = () => {
    if (inputValue.length >= 8) {
      createVehicleMutation.mutate(inputValue);
    }
  }

  return (
    <Page
      leftIcon={<CloseIcon />}
      onLeftClick={close}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("vehicleCreator.header")}</Heading>}
      rightIcon={<CorrectIcon />}
      rightDisabled={createdVehicle === undefined}
      onRightClick={() => {
        if (createdVehicle) {
          updateVehicleMutation.mutate({ vehicleId: createdVehicle.id, status: "Active" });
        }
      }}>
      <Card>
        <CardBody>
          <Heading variant="h5Strong" mb={1}>{t("vehicleCreator.searchLabel")} *</Heading>
          {createVehicleMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: createVehicleMutation.error.response?.data?.title ?? createVehicleMutation.error.message })}</Text>}
          {updateVehicleMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: updateVehicleMutation.error.response?.data?.title ?? updateVehicleMutation.error.message })}</Text>}
          <InputGroup>
            <Input
              type="search"
              placeholder="XX-XX-XX"
              onChange={event => setInputValue(event.target.value)}
              onKeyUp={event => {
                if (event.key === "Enter") {
                  onSearchClick();
                }
              }}
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                isDisabled={inputValue.length < 8}
                icon={<SearchIcon />}
                background={colorMode === "light" ? "brandLight": "primaryDark"}
                onClick={onSearchClick}
              />
            </InputRightElement>
          </InputGroup>
          {createdVehicle && <VehicleLineCard vehicle={createdVehicle} mt={4} />}
        </CardBody>
      </Card>
    </Page>
  );
}
