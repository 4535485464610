import React, { useEffect, useRef, useState } from "react";
import { IconButton, Input, InputGroup, InputRightElement, useColorMode } from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from "app/shared/icons/Search.svg";

export interface Location {
  address: string;
  latitude: number;
  longitude: number;
}

interface Props {
  placeholder?: string;
  onSearch: (terms: string, results: Location[]) => void;
}

export const SearchInput: React.FC<Props> = ({ placeholder, onSearch }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { colorMode } = useColorMode();
  
  const [inputValue, setInputValue] = useState<string>("");
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | undefined>();

  const onSearchClick = () => {
    if (placesService && inputValue.length >= 3) {
      placesService.findPlaceFromQuery({ query: inputValue, fields: ["formatted_address", "geometry"] }, (results) => {
        if (results?.length) {
          const places = results.map(place => ({
            address: place.formatted_address ?? "",
            latitude: place.geometry?.location?.lat() ?? 0,
            longitude: place.geometry?.location?.lng() ?? 0
          }));

          onSearch(inputValue, places);
        }
      });
    }
  }

  useEffect(() => {
    if (placesService === undefined && ref?.current) {
      setPlacesService(new google.maps.places.PlacesService(ref.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  return (
    <InputGroup>
      <Input
        type="search"
        ref={ref}
        placeholder={placeholder}
        onChange={event => setInputValue(event.target.value)}
        onKeyUp={event => {
          if (event.key === "Enter") {
            onSearchClick();
          }
        }}
      />
      <InputRightElement>
        <IconButton
          aria-label="Search"
          isDisabled={inputValue.length < 3}
          icon={<SearchIcon />}
          background={colorMode === "light" ? "brandLight": "primaryDark"}
          onClick={onSearchClick}
        />
      </InputRightElement>
    </InputGroup>
  );
}
