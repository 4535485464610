import { Text, Stack, StackProps } from "@chakra-ui/react";
import { WorkshopRating } from "app/bookings/models";
import React from "react";
import { RatingStars } from "./RatingStars";

interface Props extends StackProps {
  rating?: WorkshopRating;
}

export const RatingLabel: React.FC<Props> = ({ rating, ...props }) => {
  const value = rating?.value ?? 0;
  const count = rating?.count ?? 0;

  return (
    <Stack direction="row" alignItems="center" {...props}>
      {value > 0 && <Text variant="h6Strong" colorScheme="primaryV1">{value}</Text>}
      <RatingStars stars={value} />
      <Text variant="h6Strong" colorScheme="primaryV1">({count})</Text>
    </Stack>
  );
}
