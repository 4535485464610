import React from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { Page } from "app/shared";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { SignedUrl } from "../models";
import { getChatSession } from "../services";
import { useQuery } from "@tanstack/react-query";

interface Props {
  bookingId?: string;
  close: () => void;
}

export const Help: React.FC<Props> = ({ bookingId, close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const query = useQuery<SignedUrl, AxiosError<ResponseError>>({
    queryKey: ["chatSession", bookingId],
    queryFn: () => getChatSession(bookingId),
    staleTime: 0 // fresh results every time
  });

  return (
    <Page
      leftIcon={<ArrowLeftIcon />}
      onLeftClick={() => close()}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("help.header")}</Heading>}
      p={0}>
        {query.data?.url && (
          <iframe
            src={query.data.url}
            title="Help"
            width="100%"
            height="100%"
          />
        )}
    </Page>
  );
}
