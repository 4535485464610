import { fontsStyle } from "./Text";

const Textarea = {
  variants: {
    outline: {
      ...fontsStyle.h5Regular,
      bg: "primaryV2Light",
      _dark: {
        bg: "primaryV2Dark"
      },
      _hover: {
        borderColor: "primaryLight",
        _dark: {
          borderColor: "primaryDark",
        }
      },
      _focusVisible: {
        borderColor: "primaryLight",
        _dark: {
          borderColor: "primaryDark",
        }
      },
      _placeholder: {
        color: "primaryV1Light",
        _dark: {
          borderColor: "primaryV1Dark"
        }
      }
    }
  },
  defaultProps: {
    variant: "outline"
  },
};

export default Textarea;
