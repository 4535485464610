import { Heading, Text, useColorMode, Card, CardBody, CardProps, Stack, Box } from "@chakra-ui/react";
import { BookingListItem } from "app/bookings/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { servicesMap } from "./ServiceCard";
import { statusMap } from "./BookingStatusIcon";
import { ReactComponent as CalendarIcon } from "app/shared/icons/Calendar.svg";
import moment from "moment";
import { GLOBAL_NAMESPACE } from "index";
import { RatingStars } from "./RatingStars";

interface Props extends CardProps {
  booking: BookingListItem;
}

export const BookingCard: React.FC<Props> = ({ booking, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const selectedService = servicesMap.find(it => it.names.includes(booking.service.name) || it.names.includes(booking.service.id));
  const selectedStatus = statusMap.find(it => it.name === booking.status);

  return (
    <Card {...props}>
      <CardBody>
        <Stack direction="row" justifyContent="space-between">
          <Text variant="h4Strong">{selectedService ? t(selectedService.label) : booking.service.name}</Text>
          {selectedStatus ? (
            <Box
              as="span"
              mr={2}
              sx={{ "& svg > path": { fill: booking.status === "Pending" ? (colorMode === "light" ? "primaryV1Light" : "primaryV1Dark") : undefined } }}
              {...props}>
              {<selectedStatus.icon />}
            </Box>
          ) : <Text variant="h5Regular">{booking.status}</Text>}
        </Stack>
        <Heading variant="h5Regular">{booking.vehicle.plateNumber}</Heading>
        <Stack direction="row" justifyContent="space-between">
          {booking.scheduledAt ? (
            <Box display="flex" alignItems="center">
              <Box
                as="span"
                sx={{ "& svg > path": { fill: colorMode === "light" ? "primaryV1Light" : "primaryV1Dark" } }}>
                <CalendarIcon width="10px" height="10px" />
              </Box>
              <Text variant="h6Regular" colorScheme="primaryV1" ml={1}>{moment(booking.scheduledAt).utc().format("DD MMM YYYY, HH:mm")}</Text>
            </Box>
          ) : (
            <Text variant="h6Regular" colorScheme="primaryV1">{t(`shared.bookingStatus.${booking.status.toLowerCase()}`)}</Text>
          )}
          {booking.rating && <RatingStars stars={booking.rating.value} />}
        </Stack>
      </CardBody>
    </Card>
  );
}
