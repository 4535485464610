import React from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { EmptyResults, Page } from "app/shared";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";

export const NotFound: React.FC = () => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);

  return (
    <Page center={<Heading variant="h5Strong" colorScheme="brand">{t("notFound.header")}</Heading>}>
      <EmptyResults
        icon={<CloseIcon />}
        title={t("notFound.header")}
        description={t("notFound.emptyResultsDescription")}
      />
    </Page>
  );
}
