import React, { useState } from "react";
import { Box, Heading, Stack, Text, Button, Input, useColorMode } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getBookingSlots } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { Slot } from "../models";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GLOBAL_NAMESPACE } from "index";

interface Props {
  bookingId: string;
  onSlotSelected: (slotId: string) => void;
}

export const SlotSelector: React.FC<Props> = ({ bookingId, onSlotSelected }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();

  const [defaultValue, setDefaultValue] = useState<string | undefined>();
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const query = useQuery<Slot[], AxiosError<ResponseError>>({
    queryKey: ["slots"],
    queryFn: () => {
      const startDate = moment();
      const endDate = moment(startDate).add(30, "days").endOf("day");
      return getBookingSlots(bookingId, startDate.toISOString(), endDate.toISOString());
    },
    staleTime: 0, // fresh results every time
  });

  const slotDates = query.data?.map(it => moment(it.date)) ?? [moment()];

  return (
    <Box>
      <Heading variant="h5Regular" mb={5}>{t("slotSelector.slotDescription")}</Heading>
      {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
      {query.isFetching ? (
        <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>
      ) : (
        <>
          <Heading variant="h5Strong" colorScheme="primaryV1" mb={2}>{t("slotSelector.date")} *</Heading>
          <Input
            type="date"
            min={moment.min(slotDates).format("YYYY-MM-DD")}
            max={moment.max(slotDates).format("YYYY-MM-DD")}
            onChange={event => setSelectedDate(event.target.value)}
          />
          {selectedDate && (
            <Stack direction="column" mt={4}>
              <Heading variant="h5Strong" colorScheme="primaryV1">{t("slotSelector.hour")} *</Heading>
              {query.data?.filter(it => it.date === selectedDate)?.map(slot =>
                <Button
                  key={slot.id}
                  variant="outline"
                  onClick={() => {
                    onSlotSelected(slot.id);
                    setDefaultValue(slot.id);
                  }}
                  borderColor={slot.id === defaultValue ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}>
                  {moment(`${slot.date}T${slot.startTime}`).utc().format("HH:mm")} - {moment(`${slot.date}T${slot.endTime}`).utc().format("HH:mm")}
                </Button>
              )}
            </Stack>
          )}
        </>
      )}
    </Box>
  );
}
