import React, { useEffect, useRef, useState } from "react";
import { Box, useColorMode } from "@chakra-ui/react";
import { Cluster, ClusterStats, MarkerClusterer, Renderer, SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import MapStyleLight from "../static/map_style_light.json";
import MapStyleDark from "../static/map_style_dark.json";
import PinLight from "app/shared/icons/PinLight.svg";
import PinDark from "app/shared/icons/PinDark.svg";
import LocationLight from "app/shared/icons/LocationLight.svg";
import LocationDark from "app/shared/icons/LocationDark.svg";

export interface Position {
  latitude: number;
  longitude: number;
}

export interface Marker {
  id: string;
  position: Position;
}

interface Props {
  center: Position;
  zoom: number;
  markers: Marker[];
  onMarkerClick?: (marker: Marker) => void;
  onMapClick?: (position: Position) => void;
  showCenter?: boolean;
}

class CustomClusterRenderer implements Renderer {
  colorMode: "light" | "dark";

  constructor(colorMode: "light" | "dark") {
    this.colorMode = colorMode;
  }

  render(cluster: Cluster, stats: ClusterStats, map: google.maps.Map): google.maps.Marker {
      // Create svg url with fill color
      const svg = window.btoa(`
        <svg fill="${this.colorMode === "light" ? "#212121" : "#DEDEDE"}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle cx="120" cy="120" opacity="1" r="70" />
        <circle cx="120" cy="120" opacity=".2" r="90" />
        <circle cx="120" cy="120" opacity=".1" r="110" />
        </svg>
      `);

    // Create marker using svg icon
    return new google.maps.Marker({
      position: cluster.position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(cluster.count),
        color: this.colorMode === "light" ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)",
        fontSize: "12px",
        fontWeight: "bold"
      },

      // Adjust zIndex to be above other markers
      zIndex: 1000 + cluster.count,
    });
  }
}

export const Map: React.FC<Props> = (props) => {
  const { colorMode } = useColorMode();
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map | undefined>();
  const [clusterer, setClusterer] = useState<MarkerClusterer | undefined>();
  const [centerMarker, setCenterMarker] = useState<google.maps.Marker | undefined>();
  const markerIds = props.markers.map(marker => marker.id).sort().join(",");

  const showCenterMarker = (currentMap: google.maps.Map, newPosition: Position) => {
    const position = { lat: newPosition.latitude, lng: newPosition.longitude };

    if (centerMarker) {
      centerMarker.setPosition(position);
    } else {
      const newCenterMarker = new google.maps.Marker({
        map: currentMap,
        position,
        icon: {
          url: colorMode === "light" ? LocationLight : LocationDark
        }
      });
      setCenterMarker(newCenterMarker);
    }
  }

  const addMarkers = (currentMap: google.maps.Map, markers: Marker[]) => {
    if (currentMap) {
      const newMarkers = markers.map(marker => {
        const newMarker = new google.maps.Marker({
          map: currentMap,
          position: { lat: marker.position.latitude, lng: marker.position.longitude },
          icon: {
            url: colorMode === "light" ? PinLight : PinDark
          },
        });

        if (props.onMarkerClick) {
          newMarker.addListener("click", () => props.onMarkerClick?.(marker));
        }

        return newMarker;
      });

      if (clusterer) {
        clusterer.clearMarkers();
        clusterer.addMarkers(newMarkers);
      } else {
        const newMarkerClusterer = new MarkerClusterer({
          algorithm: new SuperClusterAlgorithm({}),
          map: currentMap,
          markers: newMarkers,
          renderer: new CustomClusterRenderer(colorMode)
        });
        setClusterer(newMarkerClusterer);
      }
    }
  }

  useEffect(() => {
    if (map === undefined && ref?.current) {
      const newMap = new window.google.maps.Map(ref.current, {
        center: { lat: props.center.latitude, lng: props.center.longitude },
        zoom: props.zoom,
        clickableIcons: false,
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        keyboardShortcuts: false,
        styles: colorMode === "light" ? MapStyleLight : MapStyleDark
      });

      if (props.showCenter) {
        showCenterMarker(newMap, props.center);
      }
      if (props.markers.length) {
        addMarkers(newMap, props.markers);
      }

      if (props.onMapClick) {
        newMap.addListener("click", (event: any) => {
          props.onMapClick?.({ latitude: event.latLng.lat(), longitude: event.latLng.lng() });
        });
      }

      setMap(newMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current]);

  useEffect(() => {
    if (map) {
      addMarkers(map, props.markers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerIds]);

  useEffect(() => {
    if (map) {
      if (props.showCenter) {
        showCenterMarker(map, props.center);
      }

      map.setCenter({ lat: props.center.latitude, lng: props.center.longitude });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.center.latitude, props.center.longitude]);

  useEffect(() => {
    if (map) {
      map.setZoom(props.zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.zoom]);

  return (
    <Box ref={ref} id="map" width="100%" height="100%" />
  );
}
