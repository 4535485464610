import { useColorMode, Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {
  currentStep: number;
  totalSteps: number;
}

export const StepIndicator: React.FC<Props> = ({ currentStep, totalSteps, ...props }) => {
  const { colorMode } = useColorMode();
  const filledWidth = Math.min((100 / totalSteps) * currentStep, 100);

  return (
    <Box display="flex" width="100%" height="3px" {...props}> 
      <Box
        width={`${filledWidth}%`}
        height="3px"
        bg={colorMode === "light" ? "primaryLight" : "brandLight"}
      />
      <Box
        bg={colorMode === "light" ? "primaryLight" : "brandLight"}
        opacity="33%"
        flex={1}
      />
    </Box>
  );
}
