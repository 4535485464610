import React, { useState } from "react";
import { Box, Button, Card, CardBody, Heading, Modal, ModalContent, ModalOverlay, Stack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { Page, StepLabel } from "app/shared";
import { VehiclesList } from "./VehiclesList";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";
import { ReactComponent as HelpIcon } from "app/shared/icons/Help.svg";
import { ReactComponent as ArrowRightIcon } from "app/shared/icons/ArrowRight.svg";
import { Vehicle, WorkflowStep } from "../models";
import { servicesMap } from "app/shared/ServiceCard";
import { Help } from "./Help";
import { AxiosError } from "axios";
import i18n from "i18n";
import { ResponseError } from "network";
import { getServiceSteps } from "../services";
import { useQuery } from "@tanstack/react-query";

interface Props {
  serviceId: string;
  onVehicleSelect: (vehicle: Vehicle) => void;
  close: () => void;
}

export const ServiceVehicle: React.FC<Props> = ({ serviceId, onVehicleSelect, close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | undefined>();
  const [isScrollBottom, setScrollBottom] = useState(false);

  const query = useQuery<WorkflowStep[], AxiosError<ResponseError>>({
    queryKey: ["services", serviceId],
    queryFn: () => getServiceSteps(serviceId, i18n.language)
  });

  return (
    <Page
      leftIcon={<CloseIcon />}
      onLeftClick={close}
      center={<Heading variant="h5Strong" colorScheme="brand">{t(servicesMap.find(it => it.names.includes(serviceId))!.label!)}</Heading>}
      rightIcon={<HelpIcon />}
      onRightClick={() => setHelpModalOpen(true)}
      currentStep={selectedVehicle ? 1 : undefined}
      totalSteps={selectedVehicle ? (query.data?.length ?? 6) + 1 : undefined}
      onScrollChange={isBottom => setScrollBottom(isBottom)}>
      <Box display="flex" flexDirection="column" height="100%">
        <Card>
          <CardBody>
            <StepLabel step={1} label={t("serviceVehicle.stepTitle") ?? ""} />
            <Text variant="h5Strong" colorScheme="primaryV1" mt={4} mb={2}>{t("serviceVehicle.stepDescription")} *</Text>
            <VehiclesList onVehicleSelect={vehicle => setSelectedVehicle(vehicle)} deleteDisabled={true} isScrollBottom={isScrollBottom} />
          </CardBody>
        </Card>
        <Box flex={1} />
        {selectedVehicle && (
          <Stack direction="row" justifyContent="space-between" mt={4}>
            <Button
              width="100%"
              variant="brand"
              rightIcon={<ArrowRightIcon />}
              iconSpacing="auto"
              onClick={() => {
                if (selectedVehicle) {
                  onVehicleSelect(selectedVehicle)
                }
              }}>
              {query.data?.[0]?.title ?? t("serviceVehicle.next")}
            </Button>
          </Stack>
        )}
      </Box>
      <Modal isOpen={isHelpModalOpen} onClose={() => setHelpModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <Help close={() => setHelpModalOpen(false)} />
        </ModalContent>
      </Modal>
    </Page>
  );
}
