import { fontsStyle } from "./Text";

const NumberInput = {
  variants: {
    outline: {
      field: {
        ...fontsStyle.h5Regular,
        bg: "primaryV2Light",
        _dark: {
          bg: "primaryV2Dark"
        },
        _hover: {
          borderColor: "primaryLight",
          _dark: {
            borderColor: "primaryDark",
          }
        },
        _focusVisible: {
          borderColor: "primaryLight",
          _dark: {
            borderColor: "primaryDark",
          }
        }
      }
    }
  },
  defaultProps: {
    variant: "outline"
  },
};

export default NumberInput;
