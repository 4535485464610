import React from "react";
import { Heading, Text, Image, Card, CardBody, Tabs, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react";
import { Workshop, WorkshopDaySchedule } from "../models";
import { useTranslation } from "react-i18next";
import { DayScheduleLineCard, LineCard, Page, RatingLabel } from "app/shared";
import { GLOBAL_NAMESPACE } from "index";
import { ReactComponent as ArrowBottomSmallIcon } from "app/shared/icons/ArrowBottomSmall.svg";
import { servicesMap } from "app/shared/ServiceCard";
import { useSearchParams } from "react-router-dom";
import { daysMap } from "app/shared/DayScheduleLineCard";

interface Props {
  workshop: Workshop;
  close: () => void;
  disableLinks?: boolean;
}

export const WorkshopViewer: React.FC<Props> = ({ workshop, close, disableLinks }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const [, setSearchParams] = useSearchParams();

  const fillClosedDays = (openHours: WorkshopDaySchedule[]) => {
    return daysMap.map(day => {
      const match = openHours.find(it => it.weekDay === day.weekDay);
  
      return { weekDay: day.weekDay, startTime: match?.startTime, endTime: match?.endTime };
    })
  }

  return (
    <Page leftIcon={<ArrowBottomSmallIcon />} onLeftClick={() => close()}>
      <Heading variant="h2Strong">{workshop.name}</Heading>
      <RatingLabel rating={workshop.rating} mt={2} />
      {workshop.coverImage && (
        <Image
          src={workshop.coverImage}
          width="100%"
          height={["200px", "300px"]}
          objectFit="contain"
          mt={4}
          borderRadius="default"
        />
      )}
      <Card mt={4}>
        <CardBody>
          <Heading variant="h3Strong" mb={3}>{t("workshopViewer.address")}</Heading>
          <Text variant="h5Regular">{[workshop.street, workshop.city, workshop.postalCode, workshop.country].filter(it => it).join(", ")}</Text>
        </CardBody>
      </Card>
      <Card mt={4}>
        <CardBody>
          <Heading variant="h3Strong" mb={3}>{t("workshopViewer.services")}</Heading>
          <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{t("workshopViewer.servicesDescription")}</Text>
          {workshop.services.map(service => {
            const selectedService = servicesMap.find(it => it.names.includes(service.name) || it.names.includes(service.id));

            return selectedService ? (
              <LineCard
                key={service.id}
                icon={<selectedService.iconSmall />}
                pl={0}
                label={t(selectedService.label)}
                onClick={disableLinks ? undefined : () => setSearchParams({ tab: "services", serviceId: service.id })}
              />
            ) : service.name;
          })}
        </CardBody>
      </Card>
      {workshop.openHours.length > 0 && (
        <Card mt={4}>
          <CardBody>
            <Heading variant="h3Strong" mb={3}>{t("workshopViewer.schedule")}</Heading>
            <Tabs isFitted defaultIndex={workshop.openHours.some(it => it.type === "Store") ? 0 : 1}>
              {workshop.openHours.some(it => it.type === "Store") && workshop.openHours.some(it => it.type === "Residence") && (
                <TabList>
                  <Tab>{t("workshopViewer.store")}</Tab>
                  <Tab>{t("workshopViewer.residence")}</Tab>
                </TabList>
              )}
              <TabPanels>
                <TabPanel mt={5}>
                  {fillClosedDays(workshop.openHours.filter(it => it.type === "Store")).map((openHour, key) =>
                    <DayScheduleLineCard key={key} weekDay={openHour.weekDay} startTime={openHour.startTime} endTime={openHour.endTime} />
                  )}
                </TabPanel>
                <TabPanel mt={5}>
                  {fillClosedDays(workshop.openHours.filter(it => it.type === "Residence")).map((openHour, key) =>
                    <DayScheduleLineCard key={key} weekDay={openHour.weekDay} startTime={openHour.startTime} endTime={openHour.endTime} />
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </CardBody>
        </Card>
      )}
    </Page >
  );
}
