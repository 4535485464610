module.exports = {
  production: true,
  api: "https://api.pluxapp.com/v1",
  googleMapsKey: "AIzaSyAKjbW7Sh9dNJBjvaJFipFPO2NGrV330mk",
  amplify: {
    region: "eu-west-1",
    userPoolId: "<REPLACE>",
    userPoolWebClientId: "<REPLACE>",
  },
};
