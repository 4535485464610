import React, { useState } from "react";
import { Box, Heading, Text, SimpleGrid, Card, CardBody, useColorMode } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getBookingOptions } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { Option } from "../models";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { EmptyResults, OptionLineCard } from "app/shared";
import { ReactComponent as CanceledIcon } from "app/shared/icons/Canceled.svg";

interface Props {
  bookingId: string;
  onOptionSelected: (optionId: string) => void;
}

export const OptionSelector: React.FC<Props> = ({ bookingId, onOptionSelected }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();

  const [defaultValue, setDefaultValue] = useState<string | undefined>();
  const query = useQuery<Option[], AxiosError<ResponseError>>({
    queryKey: ["options"],
    queryFn: () => getBookingOptions(bookingId),
    staleTime: 0 // fresh results every time
  });

  return (
    <Box>
      {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
      {query.isFetching ? (
        <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>
      ) : (
        query.data?.length ? (
          <>
            <Heading variant="h5Strong" colorScheme="primaryV1" mb={2}>{t("optionSelector.optionDescription")} *</Heading>
            <SimpleGrid spacing={4}>
              {query.data?.map(option =>
                <Card
                  key={option.id}
                  borderWidth={1}
                  borderColor={option.id === defaultValue ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}
                  onClick={() => {
                    onOptionSelected(option.id);
                    setDefaultValue(option.id);
                  }}>
                  <CardBody>
                    <OptionLineCard option={option} />
                  </CardBody>
                </Card>
              )}
              <Text variant="h6Regular" colorScheme="primaryV1" mt={2}>{t("bookingViewer.optionDescription")}</Text>
            </SimpleGrid>
          </>
        ) : (
          <EmptyResults
            icon={<CanceledIcon />}
            title={t("optionSelector.emptyResultsTitle")}
            description={t("optionSelector.emptyResultsDescription")}
          />
        )
      )}
    </Box>
  );
}
