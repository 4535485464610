import { fontsVariants } from "./Text";

const Heading = {
  variants: {...fontsVariants},
  defaultProps: {
    variant: "h3Strong",
    colorScheme: "primary",
  },
};

export default Heading;
