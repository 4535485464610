import { Text, StackProps } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as CarEmptyIcon } from "app/shared/icons/CarEmpty.svg";
import { Option } from "app/bookings/models";
import { IconCard } from "./IconCard";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";

interface Props extends StackProps {
  option: Option;
}

export const OptionLineCard: React.FC<Props> = ({ option, onClick, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);

  return (
    <IconCard icon={option.optionImage} fallbackIcon={<CarEmptyIcon />} {...props}>
      <Text variant="h4Strong">{option.title}</Text>
      <Text variant="h5Regular">{option.price === 0 ? t("shared.optionLineCard.noPrice") : `${option.price} ${option.currency}`}</Text>
      <Text variant="h6Strong" colorScheme="primaryV1">{option.description}</Text>
    </IconCard>
  );
}
