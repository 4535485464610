import React, { ReactElement } from "react";
import { Heading, Text, Stack } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getNotificationSettings, updateNotificationSettings } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { UserNotificationListItem } from "../models";
import { useTranslation } from "react-i18next";
import { UpdateNotificationSettingsCommand } from "../models/commands";
import { GLOBAL_NAMESPACE } from "index";
import { NotificationSettingCard, Page } from "app/shared";

interface Props {
  headerLeftIcon: ReactElement;
  onHeaderLeftClick: () => void;
}

export const NotificationSettings: React.FC<Props> = ({ headerLeftIcon, onHeaderLeftClick }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const queryClient = useQueryClient();

  const query = useQuery<UserNotificationListItem[], AxiosError<ResponseError>>({ queryKey: ["notificationSettings"], queryFn: getNotificationSettings });
  const mutation = useMutation<UserNotificationListItem[], AxiosError<ResponseError>, UpdateNotificationSettingsCommand>({
    mutationFn: (data) => updateNotificationSettings(data),
    onSuccess: (updatedNotificationSettings) => {
      if (updatedNotificationSettings.length) {
        const updatedNotificationSetting = updatedNotificationSettings?.[0];

        queryClient.setQueryData<UserNotificationListItem[]>(["notificationSettings"], (previous) => {
          if (previous) {
            return previous.map(notificationSetting => {
              return notificationSetting.groupKey === updatedNotificationSetting.groupKey ? updatedNotificationSetting : notificationSetting;
            });
          }

          return previous;
        });
      }
    }
  });

  const onSwitchChange = (setting: UserNotificationListItem, enabled: boolean) => {
    const newSetting = {
      ...setting,
      channels: setting.channels.map(channel => ({ ...channel, enabled }))
    };
    mutation.mutate({ updatedSettings: [newSetting] });
  }

  return (
    <Page
      leftIcon={headerLeftIcon}
      onLeftClick={onHeaderLeftClick}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("notificationSettings.header")}</Heading>}>
      {query.isFetching && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>}
      {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
      <Stack spacing={4}>
        {query.data?.filter(setting => ["BookingCompleted", "BookingBilled"].includes(setting.groupKey))?.map(setting => (
          <NotificationSettingCard
            key={setting.groupKey}
            title={t(`notificationSettings.${setting.groupKey.toLowerCase()}Title`) ?? setting.groupKey}
            description={t(`notificationSettings.${setting.groupKey.toLowerCase()}Description`) ?? setting.groupKey}
            defaultChecked={setting.channels.some(channel => channel.enabled)}
            onCheckedChange={isChecked => onSwitchChange(setting, isChecked)}
          />
        ))}
      </Stack>
    </Page>
  );
}
