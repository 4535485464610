module.exports = {
  production: false,
  api: "http://localhost:8080",
  googleMapsKey: "AIzaSyAKjbW7Sh9dNJBjvaJFipFPO2NGrV330mk",
  amplify: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_RVOjL9HHA",
    userPoolWebClientId: "7m2ebnm5ms9gksqapjcu9g6vmf",
  },
};
