import React, { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { ThemeContext, getThemeStyle, getTheme, THEME_NAME, DEFAULT_THEME_NAME } from "theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Homepage } from "app/bookings";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Wrapper } from "@googlemaps/react-wrapper";
import environment from "configurations";
import { Authentication } from "app/bookings/components/Authentication";
import { NotFound } from "app/bookings/components/NotFound";
import Fonts from "theme/Fonts";
import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

Amplify.configure({ Auth: environment.amplify });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000,
      retry: false
    }
  }
});

export const App: React.FC = () => {
  const [themeName, setThemeName] = useState(localStorage.getItem(THEME_NAME) ?? DEFAULT_THEME_NAME);
  const { authStatus } = useAuthenticator((context) => [context.user]);
  const isAuthenticated = authStatus === "authenticated";

  const setTheme = (name: string) => {
    localStorage.setItem(THEME_NAME, name);
    setThemeName(name);
  }

  return (
    <ThemeContext.Provider value={{ themeName: themeName, theme: getThemeStyle(themeName), setTheme }}>
      <ChakraProvider theme={getTheme(themeName)}>
        <Fonts />
        <QueryClientProvider client={queryClient}>
          <Wrapper apiKey={environment.googleMapsKey} libraries={["places"]}>
            <BrowserRouter>
              <Routes>
                {isAuthenticated && <Route path="/" element={<Homepage />} />}
                <Route path="/authentication" element={<Authentication />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Wrapper>
        </QueryClientProvider>
      </ChakraProvider>
    </ThemeContext.Provider>
  );
}
