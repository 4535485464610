import { FunctionComponent, createContext } from "react";
import { extendTheme } from "@chakra-ui/react";
import { ReactComponent as PluxIcon } from "app/shared/icons/Plux.svg";
import { ReactComponent as LogoIcon } from "app/shared/icons/Logo.svg";
import Button from "./Button";
import Tabs from "./Tabs";
import Modal from "./Modal";
import Heading from "./Heading";
import Text from "./Text";
import Card from "./Card";
import Select from "./Select";
import Input from "./Input";
import Textarea from "./Textarea";
import Checkbox from "./Checkbox";
import NumberInput from "./NumberInput";

export const HEADER_SIZE = "56px";
export const FOOTER_SIZE = "42px";
export const THEME_NAME = "theme";
export const DEFAULT_THEME_NAME = "plux";

interface ThemeContextParams {
  theme: ThemeConfiguration;
  themeName: string;
  setTheme: (name: string) => void;
}

export const ThemeContext = createContext<ThemeContextParams>(null as any);

interface ThemeConfiguration {
  names: string[];
  logo: FunctionComponent;
  brandLight: string;
  brandDark: string
}

const themes: ThemeConfiguration[] = [
  {
    names: ["plux"],
    logo: PluxIcon,
    brandLight: "#6C7DBC",
    brandDark: "#1b1b1b"
  },
  {
    names: ["2nm12jhmfvatci6qi6uf97tuas", "7mpvpier5qannutgthlr0opfba"],
    logo: LogoIcon,
    brandLight: "#67de99",
    brandDark: "#1b1b1b"
  }
];

export const getThemeStyle = (name: string) => {
  return themes.find(it => it.names.includes(name)) ?? themes[0];
}

export const getTheme = (name: string) => {
  const theme = getThemeStyle(name);

  return extendTheme({
    styles: {
      global: {
        "html, body": {
          _light: {
            backgroundColor: "backgroundLight.100",
          },
          _dark: {
            backgroundColor: "backgroundDark.100",
          },
        },
        "html, body, #root": {
          height: "100%"
        },
      },
    },
    fonts: {
      heading: `'Poppins', sans-serif`,
      body: `'Poppins', sans-serif`,
    },
    colors: {
      white: { 100: "#ffffff" },
      backgroundLight: { 100: "#f4f4f4" },
      backgroundDark: { 100: "#212121" },
      primaryLight: "#212121",
      primaryDark: "#DEDEDE",
      primaryV1Light: "#6E6E6E",
      primaryV1Dark: "#A3A3A3",
      primaryV2Light: "#E3E3E3",
      primaryV2Dark: "#424242",
      primaryV3Light: "#F5F5F5",
      primaryV3Dark: "#2E2E2E",
      secondaryLight: "#FFFFFF",
      secondaryDark: "#212121",
      secondaryV1Light: "#F1F1F1",
      secondaryV1Dark: "#696969",
      secondaryV2Light: "#E4E4E4",
      secondaryV2Dark: "#B8B8B8",
      positiveLight: "#297D5B",
      positiveDark: "#54B889",
      positiveV1Light: "#E1E8E5",
      positiveV1Dark: "#28352F",
      negativeLight: "#AC2D51",
      negativeDark: "#E88291",
      negativeV1Light: "#ECE3E6",
      negativeV1Dark: "#3B2E30",
      alertLight: "#B58200",
      alertDark: "#F2CE72",
      alertV1Light: "#ECE7DA",
      alertV1Dark: "#3C372B",
      brandLight: theme.brandLight,
      brandDark: theme.brandDark
    },
    radii: {
      none: "0",
      default: "6px",
      default2x: "16px",
    },
    components: {
      Heading,
      Text,
      Button,
      Tabs,
      Modal,
      Card,
      Select,
      Input,
      Textarea,
      Checkbox,
      NumberInput
    },
  });
}
