import { Text, StackProps, useColorMode, Box, IconButton } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as TrashIcon } from "app/shared/icons/Trash.svg";
import { ReactComponent as CarEmptyIcon } from "app/shared/icons/CarEmpty.svg";
import { Vehicle } from "app/bookings/models";
import { IconCard } from "./IconCard";

interface Props extends StackProps {
  vehicle: Vehicle;
  onDelete?: () => void;
}

export const VehicleLineCard: React.FC<Props> = ({ vehicle, onDelete, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <IconCard
      icon={vehicle.vehicleImage}
      fallbackIcon={<CarEmptyIcon />}
      rightElement={onDelete && (
        <IconButton
          variant="ghost"
          aria-label="Delete"
          icon={<Box as="span" sx={{ "& svg path": { fill: colorMode === "light" ? "negativeLight" : "negativeDark" } }}><TrashIcon /></Box>}
          onClick={onDelete}
        />
      )}
      {...props}>
        <Text variant="h4Strong">{vehicle.plateNumber}</Text>
        <Text variant="h5Regular">{vehicle.make} {vehicle.model}</Text>
        <Text variant="h6Strong" colorScheme="primaryV1">{vehicle.buildDate}</Text>
    </IconCard>
  );
}
