const Tabs = {
  variants: {
    tabbar: {
      tablist: {
        borderTopRadius: "default2x",
        _light: {
          background: "white.100",
        },
        _dark: {
          background: "primaryV3Dark",
        },
      },
      tab: {
        _dark: {
          _selected: {
            "svg > path": {
              fill: "primaryDark"
            }
          }
        }
      },
      tabpanel: {
        height: "100%"
      }
    },
    default: {
      tab: {
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        borderStyle: "solid",
        borderColor: "primaryLight",
        padding: 2,
        _dark: {
          borderColor: "primaryDark",
        },
        _selected: {
          borderRadius: "default",
          borderStyle: "solid",
          borderColor: "primaryLight",
          borderWidth: "2px",
          _dark: {
            borderColor: "primaryDark",
          },
        }
      },
      tablist: {
        justifyContent: "center",
        "& > button:first-of-type": {
          borderLeftWidth: "1px",
          borderLeftRadius: "default",
        },
        "& > button:last-of-type": {
          borderRightWidth: "1px",
          borderRightRadius: "default",
        }
      },
    },
    fullHeight: {
      root: {
        height: "100%",
        "& > *": {
          height: "100%"
        }
      },
      tabpanel: {
        height: "100%",
      }
    },
  },
  baseStyle: {
    tabpanel: {
      padding: 0
    }
  },
  defaultProps: {
    variant: "default"
  },
};

export default Tabs;
