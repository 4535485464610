import React, { useState } from "react";
import { Box, Text, TabList, Tabs, TabPanels, Tab, TabPanel, Input, Modal, ModalContent, ModalOverlay, ModalCloseButton, ModalBody, IconButton, InputGroup, InputRightElement, SimpleGrid, useColorMode } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getBookings, getWorkshops } from "../services";
import { AxiosError } from "axios";
import { PagedResponse, ResponseError } from "network";
import { BookingListItem, Workshop } from "../models";
import { useTranslation } from "react-i18next";
import { Map } from "./Map";
import { WorkshopViewer } from "./WorkshopViewer";
import { GLOBAL_NAMESPACE } from "index";
import { ReactComponent as SearchIcon } from "app/shared/icons/Search.svg";
import { WorkshopCard } from "app/shared";

interface Props {
  defaultWorkshopId?: string;
  services: string[];
  categories: string[];
  onWorkshopSelected: (workshopId: string) => void;
}

export const WorkshopSelector: React.FC<Props> = ({ defaultWorkshopId, services, categories, onWorkshopSelected }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();

  const [selectedWorkshopId, setSelectedWorkshopId] = useState<string | undefined>(defaultWorkshopId);
  const [inputValue, setInputValue] = useState<string>("");
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [selectedModalWorkshop, setSelectedModalWorkshop] = useState<Workshop | undefined>();
  const [center, setCenter] = useState({ latitude: 38.7436863, longitude: -9.2014027 });
  const [zoom, setZoom] = useState(6);
  const [tabIndex, setTabIndex] = useState(0);

  const workshopsQuery = useQuery<Workshop[], AxiosError<ResponseError>>({
    queryKey: ["workshops", services, categories, searchTerms],
    queryFn: () => getWorkshops({ services, categories, terms: searchTerms })
  });
  const bookingsQuery = useQuery<PagedResponse<BookingListItem>, AxiosError<ResponseError>>({
    queryKey: ["bookings"],
    queryFn: () => getBookings({ page: 0 }),
    enabled: workshopsQuery.isFetched
  });
  const onWorkshopClick = (workshop: Workshop) => {
    onWorkshopSelected(workshop.id);
    setSelectedWorkshopId(workshop.id);
    setCenter({ latitude: workshop.latitude, longitude: workshop.longitude });
    setZoom(12);
  }
  const onSearchClick = () => {
    if (inputValue.length >= 3) {
      setSearchTerms(inputValue);
    }
  }

  const workshops = tabIndex === 0 ? (
      bookingsQuery.data?.data
        ?.filter(it => it.workshop && workshopsQuery.data?.some(workshop => workshop.id === it.workshop?.id) === true)
        ?.map(it => it.workshop!)
        ?.reduce((arr, workshop) => !arr.some(it => it.id === workshop.id) ? [...arr, workshop] : arr, [] as Workshop[])
    ) : (
      workshopsQuery.data
    );

  return (
    <Box>
      {workshopsQuery.isFetching && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>}
      {workshopsQuery.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: workshopsQuery.error.response?.data?.title ?? workshopsQuery.error.message })}</Text>}
      {bookingsQuery.isFetching && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>}
      {bookingsQuery.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: bookingsQuery.error.response?.data?.title ?? bookingsQuery.error.message })}</Text>}
      <Tabs isFitted my={4} onChange={index => {
        if (inputValue !== "") {
          setInputValue("");
          setSearchTerms("");
        }
        setTabIndex(index);
      }}>
        <TabList gridArea="footer">
          <Tab>{t("workshopSelector.recent")}</Tab>
          <Tab>{t("workshopSelector.near")}</Tab>
          <Tab>{t("workshopSelector.search")}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel />
          <TabPanel />
          <TabPanel pt={4}>
            <Text variant="h5Regular" colorScheme="primaryV1" mb={1}>{t("workshopSelector.searchPlaceholder")} *</Text>
            <InputGroup>
              <Input
                type="search"
                value={inputValue}
                onChange={event => {
                  const newValue = event.target.value;

                  if (newValue === "" && searchTerms !== "") {
                    setSearchTerms(newValue);
                  }

                  setInputValue(newValue);
                }}
                onKeyUp={event => {
                  if (event.key === "Enter") {
                    onSearchClick();
                  }
                }}
              />
              <InputRightElement>
                <IconButton
                  aria-label="Search"
                  isDisabled={inputValue.length < 3}
                  icon={<SearchIcon />}
                  background={colorMode === "light" ? "brandLight": "primaryDark"}
                  onClick={onSearchClick}
                />
              </InputRightElement>
            </InputGroup>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Box height="400px">
        <Map
          center={center}
          zoom={zoom}
          markers={workshops?.map(workshop => ({
            id: workshop.id,
            position: { latitude: workshop.latitude, longitude: workshop.longitude }
          })) ?? []}
          onMarkerClick={marker => {
            const workshop = workshops?.find(workshop => workshop.id === marker.id);

            if (workshop) {
              onWorkshopClick(workshop);
            }
          }}
        />
      </Box>
      <SimpleGrid spacing={2} mt={4}>
        {workshops?.map(workshop =>
          <WorkshopCard
            key={workshop.id}
            workshop={workshop}
            onClick={() => onWorkshopClick(workshop)}
            onInfoClick={() => setSelectedModalWorkshop(workshop)}
            borderWidth={1}
            borderColor={workshop.id === selectedWorkshopId ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}
          />
        )}
      </SimpleGrid>
      <Modal isOpen={selectedModalWorkshop !== undefined} onClose={() => setSelectedModalWorkshop(undefined)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedModalWorkshop && <WorkshopViewer workshop={selectedModalWorkshop} close={() => setSelectedModalWorkshop(undefined)} disableLinks={true} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
