import React, { useState } from "react";
import { Heading, Text, Textarea, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBookingRating } from "../services";
import { Workshop } from "../models";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { CreateRatingCommand } from "../models/commands";
import { GLOBAL_NAMESPACE } from "index";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";
import { ReactComponent as SendIcon } from "app/shared/icons/Send.svg";
import { Page, RatingStars } from "app/shared";

interface Props {
  bookingId: string;
  workshop: Workshop;
  close: () => void;
}

export const RatingCreator: React.FC<Props> = ({ bookingId, workshop, close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const queryClient = useQueryClient();
  const toast = useToast();

  const [rating, setRating] = useState<number | undefined>();
  const [comment, setComment] = useState<string | undefined>();

  const createRatingMutation = useMutation<void, AxiosError<ResponseError>, CreateRatingCommand>({
    mutationFn: (data) => createBookingRating(bookingId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bookings", bookingId] });
      queryClient.invalidateQueries({ queryKey: ["workshops"] });
      toast({
        title: t("ratingCreator.submitSuccessToast"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      close();
    }
  });

  return (
    <Page
      leftIcon={<CloseIcon />}
      onLeftClick={() => close()}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("ratingCreator.header")}</Heading>}
      rightIcon={<SendIcon />}
      rightDisabled={rating === undefined || comment === undefined}
      onRightClick={() => {
        if (rating && comment) {
          createRatingMutation.mutate({ rating, comment });
        }
      }}>
      {createRatingMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: createRatingMutation.error.response?.data?.title ?? createRatingMutation.error.message })}</Text>}
      <Text variant="h5Regular" mb={5}>{t("ratingCreator.rateWorkshop", { name: workshop.name })}</Text>
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{t("ratingCreator.rate")} *</Text>
      <RatingStars stars={rating ?? 0} size="30px" onStarClick={stars => setRating(stars)} />
      <Text variant="h5Strong" colorScheme="primaryV1" mt={7} mb={2}>{t("ratingCreator.comment")}</Text>
      <Textarea
        minLength={1}
        onChange={event => setComment(event.target.value)}
      />
    </Page>
  );
}
