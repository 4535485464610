import { Text, BoxProps, Box, useColorMode } from "@chakra-ui/react";
import { Service } from "app/bookings/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { servicesMap } from "./ServiceCard";
import { GLOBAL_NAMESPACE } from "index";

interface Props extends BoxProps {
  service: Service;
}

export const ServiceLabel: React.FC<Props> = ({ service, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const selectedService = servicesMap.find(it => it.names.includes(service.name) || it.names.includes(service.id));

  return (
    <Box display="flex" alignItems="center" {...props}>
      {selectedService && (
        <Box as="span" mr={2} sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}>
          {<selectedService.iconSmall />}
        </Box>
      )}
      <Text variant="h5Strong">{selectedService ? t(selectedService.label) : service.name}</Text>
    </Box>
  );
}
