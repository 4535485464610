import { Heading, Text, useColorMode, Card, CardBody, CardProps, Stack, Box, IconButton } from "@chakra-ui/react";
import React, { useState } from "react";
import { ReactComponent as SwitchLightOnIcon } from "app/shared/icons/SwitchLightOn.svg";
import { ReactComponent as SwitchLightOffIcon } from "app/shared/icons/SwitchLightOff.svg";
import { ReactComponent as SwitchDarkOnIcon } from "app/shared/icons/SwitchDarkOn.svg";
import { ReactComponent as SwitchDarkOffIcon } from "app/shared/icons/SwitchDarkOff.svg";

interface Props extends CardProps {
  title: string;
  description: string;
  defaultChecked?: boolean;
  onCheckedChange: (isChecked: boolean) => void;
}

export const NotificationSettingCard: React.FC<Props> = ({ title, description, defaultChecked, onCheckedChange, ...props }) => {
  const { colorMode } = useColorMode();
  const [isChecked, setChecked] = useState(defaultChecked ?? false);

  return (
    <Card
      cursor="pointer"
      onClick={() => {
        const newState = !isChecked;
        onCheckedChange?.(newState);
        setChecked(newState);
      }}
      {...props}>
      <CardBody>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box>
            <Heading variant="h4Strong" colorScheme={isChecked ? "positive" : undefined}>{title}</Heading>
            <Text variant="h6Regular" colorScheme="primaryV1">{description}</Text>
          </Box>
          <IconButton
            variant="ghost"
            aria-label="Toggle"
            icon={isChecked ? (
              colorMode === "light" ? <SwitchLightOnIcon /> : <SwitchDarkOnIcon />
            ) : (
              colorMode === "light" ? <SwitchLightOffIcon /> : <SwitchDarkOffIcon />
            )}
          />
        </Stack>
      </CardBody>
    </Card>
  );
}
