import { fontsStyle } from "./Text";

const Checkbox = {
  baseStyle: {
    control: {
      borderRadius: "default",
      width: "24px",
      height: "24px",
      bg: "primaryV2Light",
      borderColor: "primaryV2Light",
      _dark: {
        bg: "primaryV2Dark",
        borderColor: "primaryV2Dark",
      },
      _checked: {
        bg: "white.100",
        color: "primaryLight",
        borderColor: "primaryLight",
        _dark: {
          color: "primaryDark",
          borderColor: "primaryDark",
        },
      }
    },
    label: {
      ...fontsStyle.h5Regular,
      _checked: {
        ...fontsStyle.h5Strong
      }
    }
  },
  sizes: {
    md: {
      label: { fontSize: fontsStyle.h5Regular.fontSize }
    }
  },
  defaultProps: {
    size: "md"
  },
};

export default Checkbox;
