import React from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs, Box } from "@chakra-ui/react";
import { Bookings } from "./Bookings";
import { Services } from "./Services";
import { WorkshopMap } from "./WorkshopMap";
import { useSearchParams } from "react-router-dom";
import { Notifications } from "./Notifications";
import { NotificationsCounter } from "../models";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { getNotificationsCounter } from "../services";
import { useQuery } from "@tanstack/react-query";
import { Settings } from "./Settings";
import { ReactComponent as MapIcon } from "app/shared/icons/Map.svg";
import { ReactComponent as MapSelectedIcon } from "app/shared/icons/MapSelected.svg";
import { ReactComponent as SettingsIcon } from "app/shared/icons/Settings.svg";
import { ReactComponent as SettingsSelectedIcon } from "app/shared/icons/SettingsSelected.svg";
import { ReactComponent as CalendarIcon } from "app/shared/icons/Calendar.svg";
import { ReactComponent as CalendarSelectedIcon } from "app/shared/icons/CalendarSelected.svg";
import { ReactComponent as BellIcon } from "app/shared/icons/Bell.svg";
import { ReactComponent as BellSelectedIcon } from "app/shared/icons/BellSelected.svg";
import { ReactComponent as BadgeIcon } from "app/shared/icons/Badge.svg";
import { ReactComponent as AccountIcon } from "app/shared/icons/Account.svg";
import { ReactComponent as AccountSelectedIcon } from "app/shared/icons/AccountSelected.svg";
import { FOOTER_SIZE } from "theme";

export const Homepage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryNotificationsCounter = useQuery<NotificationsCounter, AxiosError<ResponseError>>({
    queryKey: ["notificationCounter"],
    queryFn: getNotificationsCounter,
  });
  const tabsDefinition = [
    { index: 0, name: "map" },
    { index: 1, name: "services" },
    { index: 2, name: "bookings" },
    { index: 3, name: "notifications" },
    { index: 4, name: "settings" }
  ];
  const tabIndex = tabsDefinition.find(tab => tab.name === searchParams.get("tab"))?.index ?? 0;

  return (
    <Tabs
      isLazy
      isFitted
      variant="tabbar"
      display="grid"
      height="100%"
      gridTemplateAreas={`'content' 'footer'`}
      gridTemplateRows={`auto ${FOOTER_SIZE}`}
      index={tabIndex}>
      <TabPanels gridArea="content" overflow="auto">
        <TabPanel>
          <WorkshopMap />
        </TabPanel>
        <TabPanel>
          <Services />
        </TabPanel>
        <TabPanel>
          <Bookings />
        </TabPanel>
        <TabPanel>
          <Notifications unreadCount={queryNotificationsCounter?.data?.unread ?? 0} />
        </TabPanel>
        <TabPanel>
          <Settings />
        </TabPanel>
      </TabPanels>
      <TabList gridArea="footer" m={0}>
        <Tab onClick={() => setSearchParams({ tab: "map" })}>
          {tabIndex === 0 ? <MapSelectedIcon /> : <MapIcon />}
        </Tab>
        <Tab onClick={() => setSearchParams({ tab: "services" })}>
          {tabIndex === 1 ? <SettingsSelectedIcon /> : <SettingsIcon />}
        </Tab>
        <Tab onClick={() => setSearchParams({ tab: "bookings" })}>
          {tabIndex === 2 ? <CalendarSelectedIcon /> : <CalendarIcon />}
        </Tab>
        <Tab onClick={() => setSearchParams({ tab: "notifications" })}>
          <Box position="relative">
            {tabIndex === 3 ? <BellSelectedIcon /> : <BellIcon />}
            {queryNotificationsCounter?.data?.unread ? <Box position="absolute" top="-2px" right="-5px"><BadgeIcon /></Box> : null}
          </Box>
        </Tab>
        <Tab onClick={() => setSearchParams({ tab: "settings" })}>
          {tabIndex === 4 ? <AccountSelectedIcon /> : <AccountIcon />}
        </Tab>
      </TabList>
    </Tabs>
  );
}
