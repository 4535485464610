import { Text, BoxProps, Box } from "@chakra-ui/react";
import { BookingStatus } from "app/bookings/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { BookingStatusIcon } from "./BookingStatusIcon";
import { GLOBAL_NAMESPACE } from "index";

interface Props extends BoxProps {
  status: BookingStatus;
  color?: string;
}

export const BookingStatusLabel: React.FC<Props> = ({ status, color, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);

  return (
    <Box display="flex" alignItems="center" {...props}>
      <BookingStatusIcon status={status} />
      <Text variant="h5Strong" color={color}>{t(`shared.bookingStatus.${status.toLowerCase()}`)}</Text>
    </Box>
  );
}
