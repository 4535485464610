import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getServices, getWorkshops } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { Service, Workshop } from "../models";
import { Map } from "./Map";
import { Box, Button, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useColorMode } from "@chakra-ui/react";
import { WorkshopViewer } from "./WorkshopViewer";
import { Page, Scrollable, ServiceLabel } from "app/shared";
import { ReactComponent as CloseIcon } from "app/shared/icons/CloseCircle.svg";
import { ThemeContext } from "theme";

export const WorkshopMap: React.FC = () => {
  const { colorMode } = useColorMode();
  const { theme } = useContext(ThemeContext);

  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [selectedWorkshop, setSelectedWorkshop] = useState<Workshop | undefined>();
  const queryServices = useQuery({ queryKey: ["services"], queryFn: () => getServices() });
  const queryWorkshops = useQuery<Workshop[], AxiosError<ResponseError>>({
    queryKey: ["workshops", selectedService],
    queryFn: () => getWorkshops({ services: selectedService ? [selectedService.id] : undefined })
  });
  const LogoIcon = theme.logo;

  return (
    <Page
      center={
        <IconButton
          variant="unstyled"
          aria-label="Logo"
          icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "brandLight" : undefined } }}><LogoIcon /></Box>}
          onClick={() => setSelectedService(undefined)}
        />
      }
      p={0}>
      <Scrollable position="absolute" width="100%" zIndex={1} mt={4} sx={{ "& > :last-child": { mr: 2 } }}>
        {selectedService ? (
          <>
            <IconButton
              variant="solid"
              ml={2}
              aria-label="Close"
              icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><CloseIcon /></Box>}
              onClick={() => setSelectedService(undefined)}
            />
            <Button variant="solid" ml={2}>
              <ServiceLabel service={selectedService} />
            </Button>
          </>
        ) : (
          queryServices.data?.data?.map(service => (
            <Button
              key={service.id}
              variant="solid"
              ml={2}
              onClick={() => setSelectedService(service)}>
              <ServiceLabel service={service} />
            </Button>
          ))
        )}
      </Scrollable>
      <Map
        center={{ latitude: 38.7436863, longitude: -9.2014027 }}
        zoom={6}
        markers={queryWorkshops.data?.map(workshop => ({
          id: workshop.id,
          position: { latitude: workshop.latitude, longitude: workshop.longitude }
        })) ?? []}
        onMarkerClick={marker => {
          const workshop = queryWorkshops.data?.find(workshop => workshop.id === marker.id);

          if (workshop) {
            setSelectedWorkshop(workshop);
          }
        }}
      />
      <Modal isOpen={selectedWorkshop !== undefined} onClose={() => setSelectedWorkshop(undefined)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {selectedWorkshop && <WorkshopViewer workshop={selectedWorkshop} close={() => setSelectedWorkshop(undefined)} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
