import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";
import { BookingFile, BookingWorkflow, WorkflowStep as WStep } from "../models";
import { OptionInput, NumberPickerInput, TextAreaInput, FileUploadInput } from "./QuestionInputs";
import {
  OptionInput as OInput,
  NumberPickerInput as NPickerInput,
  FileUploadInput as FUploadInput,
  TextAreaInput as TAreaInput
} from "../models";
import { WorkshopSelector } from "./WorkshopSelector";
import { LocationSelector } from "./LocationSelector";
import { OptionSelector } from "./OptionSelector";
import { SlotSelector } from "./SlotSelector";
import { Location } from "./SearchInput";

interface Props {
  booking: BookingWorkflow;
  step: WStep;
  onAnswer: (questionId: string, values: string[]) => void;
  onFileCreate: (questionId: string, file: File) => Promise<BookingFile>;
  onFileDelete: (questionId: string, fileId: string) => Promise<void>;
  onWorkshopSelected: (workshopId?: string) => void;
  onOptionSelected: (optionId: string) => void;
  onSlotSelected: (slotId: string) => void;
  onLocationSelected: (location: Location) => void;
}

export const WorkflowStep: React.FC<Props> = ({ booking, step, onAnswer, onFileCreate, onFileDelete, onWorkshopSelected, onLocationSelected, onOptionSelected, onSlotSelected }) => {
  return (
    <Box>
      {step.type === "Input" && (
        <Stack spacing={4}>
          {step.questions.map(question => {
            if (question.type === "Option") {
              return <OptionInput key={question.id} input={question as OInput} onAnswer={values => onAnswer(question.id, values)} />;
            } else if (question.type === "NumberPicker") {
              return <NumberPickerInput key={question.id} input={question as NPickerInput} onAnswer={values => onAnswer(question.id, values)} />
            } else if (question.type === "FileUpload") {
              return (
                <FileUploadInput
                  key={question.id}
                  input={question as FUploadInput}
                  files={booking.files}
                  onFileCreate={file => onFileCreate(question.id, file)}
                  onFileDelete={fileId => onFileDelete(question.id, fileId)}
                />
              );
            } else if (question.type === "TextArea") {
              return <TextAreaInput key={question.id} input={question as TAreaInput} onAnswer={values => onAnswer(question.id, values)} />;
            }

            return <Text key={question.id} color="red">Input {question.type} not supported ({question.required ? "true" : "false"})</Text>;
          })}
        </Stack>
      )}
      {step.type === "Workshop" &&
        <WorkshopSelector
          defaultWorkshopId={booking.workshop?.id}
          services={booking.service ? [booking.service?.id] : []}
          categories={booking.category ? [booking.category?.id] : []}
          onWorkshopSelected={onWorkshopSelected}
        />
      }
      {step.type === "Location" &&
        <LocationSelector
          defaultWorkshopId={booking.workshop?.id}
          defaultLocation={
            booking.address && booking.latitude && booking.longitude
              ? { address: booking.address, latitude: booking.latitude, longitude: booking.longitude }
              : undefined
          }
          services={booking.service ? [booking.service?.id] : []}
          categories={booking.category ? [booking.category?.id] : []}
          onWorkshopSelected={onWorkshopSelected}
          onLocationSelected={onLocationSelected}
        />
      }
      {step.type === "Option" &&
        <OptionSelector
          bookingId={booking.id}
          onOptionSelected={onOptionSelected}
        />
      }
      {step.type === "Slot" &&
        <SlotSelector
          bookingId={booking.id}
          onSlotSelected={onSlotSelected}
        />
      }
    </Box>
  );
}
