import { Text, BoxProps, Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import moment from "moment";

export const daysMap = [
  { weekDay: 1, label: "shared.dayScheduleLineCard.monday" },
  { weekDay: 2, label: "shared.dayScheduleLineCard.tuesday" },
  { weekDay: 3, label: "shared.dayScheduleLineCard.wednesday" },
  { weekDay: 4, label: "shared.dayScheduleLineCard.thursday" },
  { weekDay: 5, label: "shared.dayScheduleLineCard.friday" },
  { weekDay: 6, label: "shared.dayScheduleLineCard.saturday" },
  { weekDay: 7, label: "shared.dayScheduleLineCard.sunday" }
]

interface Props extends BoxProps {
  weekDay: number;
  startTime?: string;
  endTime?: string;
}

export const DayScheduleLineCard: React.FC<Props> = ({ weekDay, startTime, endTime, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const selectedDay = daysMap.find(it => it.weekDay === weekDay);
  const date = new Date().toISOString().split("T")[0];

  return selectedDay ? (
    <Box display="flex" py={3} {...props}>
      <Text width="50%" variant="h5Regular">{t(selectedDay.label)}</Text>
      <Text width="50%" variant="h5Strong">
        {startTime && endTime ? (
          `${moment(`${date}T${startTime}`).utc().format("HH:mm")} - ${moment(`${date}T${endTime}`).utc().format("HH:mm")}`
        ) : (
          t("shared.dayScheduleLineCard.closed")
        )}
      </Text>
    </Box>
  ) : <Text>{weekDay}</Text>;
}
