import React, { useState } from "react";
import { Heading, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getServices, createBooking } from "../services";
import { CreateBookingCommand } from "../models/commands";
import { Booking } from "../models";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Page, ServiceCard } from "app/shared";
import { GLOBAL_NAMESPACE } from "index";
import { ServiceVehicle } from "./ServiceVehicle";

export const Services: React.FC = () => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const [selectedServiceId, setSelectedServiceId] = useState<string | undefined>(searchParams.get("serviceId") ?? undefined);

  const query = useQuery({ queryKey: ["services"], queryFn: () => getServices() });
  const mutation = useMutation<Booking, AxiosError<ResponseError>, CreateBookingCommand>({
    mutationFn: createBooking,
    onSuccess: (booking) => {
      queryClient.invalidateQueries({ queryKey: ["bookings"] });
      setSearchParams({ tab: "bookings", bookingId: booking.id, mode: "workflow" });
    }
  });
  const onVehicleSelect = (serviceId: string, vehicleId: string) => {
    mutation.mutate({ serviceId, vehicleId });
  }

  const onModalClose = () => {
    setSelectedServiceId(undefined);
    setSearchParams({ tab: "services" });
  }

  return (
    <Page center={<Heading variant="h5Strong" colorScheme="brand">{t("services.header")}</Heading>} isMainPage={true} height="initial">
      {mutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: mutation.error.response?.data?.title ?? mutation.error.message })}</Text>}
      <SimpleGrid spacing={4}>
        {query.data?.data?.map(service =>
          <ServiceCard
            key={service.id}
            service={service}
            cursor="pointer"
            onClick={() => {
              setSelectedServiceId(service.id);
              setSearchParams({ tab: "services", serviceId: service.id });
            }}
          />
        )}
      </SimpleGrid>
      <Modal isOpen={selectedServiceId !== undefined} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {selectedServiceId &&
              <ServiceVehicle
                serviceId={selectedServiceId}
                onVehicleSelect={vehicle => onVehicleSelect(selectedServiceId, vehicle.id)}
                close={onModalClose}
              />
            }
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
