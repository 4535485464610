import Axios from "axios";
import environment from "configurations";
import { Auth } from "aws-amplify";

export interface PagedResponse<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    next: string;
    prev: string;
    self: string;
  }
  meta: {
    page: number;
    size: number;
    totalElements: number;
  }
}

export interface ResponseError {
  id: string;
  httpStatusCode: number;
  title: string;
}

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json",
  },
};

const httpClient = Axios.create(configuration);

httpClient.interceptors.request.use(
  (config) => new Promise(async (resolve) => {
    const currentSession = await Auth.currentSession();

    if (currentSession) {
      config.headers!!.Authorization = `Bearer ${currentSession.getIdToken().getJwtToken()}`;
      return resolve(config);
    } else {
      return resolve(config);
    }
  }),
  (err) => Promise.reject(err)
)

export default httpClient;