import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { SUPPORTED_LANGUAGES, registerResourceBundle } from "i18n";
import { default as EN } from "./i18n/locales/en.json";
import { default as PT } from "./i18n/locales/pt.json";
import { Authenticator } from "@aws-amplify/ui-react";

export const GLOBAL_NAMESPACE = "global";

export interface AppModule {
  setupTranslations: () => void;
}

class GlobalModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.en, GLOBAL_NAMESPACE, EN);
    registerResourceBundle(SUPPORTED_LANGUAGES.pt, GLOBAL_NAMESPACE, PT);
  }
}

const modules: AppModule[] = [
  new GlobalModule()
];
modules.forEach((module) => module.setupTranslations());

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

