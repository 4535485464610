import { Stack, StackProps, Box, BoxProps, Image } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface Props extends StackProps {
  icon?: string;
  iconProps?: BoxProps;
  fallbackIcon: ReactElement;
  rightElement?: ReactElement;
}

export const IconCard: React.FC<Props> = ({ icon, fallbackIcon, iconProps, onClick, children, rightElement, ...props }) => {
  return (
    <Stack direction="row" alignItems="center" {...props}>
      <Box
        display="flex"
        width="90px"
        height="55px"
        alignItems="center"
        justifyContent="center"
        bg="white.100"
        borderRadius="5px"
        border={1}
        cursor={onClick ? "pointer" : undefined}
        onClick={onClick}
        {...iconProps}>
        {icon ? (
          <Image src={icon} maxHeight="100%" />
        ) : (
          fallbackIcon
        )}
      </Box>
      <Box
        ml={1}
        flex={1}
        cursor={onClick ? "pointer" : undefined}
        onClick={onClick}>
          {children}
      </Box>
      {rightElement}
    </Stack>
  );
}
