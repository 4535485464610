import { Box, BoxProps } from "@chakra-ui/react";
import React, { useState } from "react";
import { Header, HeaderProps } from "./Header";
import { FOOTER_SIZE, HEADER_SIZE } from "theme";
import styled from "@emotion/styled";

interface PageProps {
  isMainPage?: boolean;
  onScrollChange?: (isBottom: boolean) => void;
}

/**
 * Elements with overflow need a fixed height for onScroll to be fired.
 * Using a fallback height value in case dvh is not available: https://caniuse.com/?search=dvh
 */
const OverflowBox = styled(Box)(props => `
  overflow: auto;
  height: ${props.height ? `calc(100vh - ${props.height})`: "100%"};
  height: ${props.height ? `calc(100dvh - ${props.height})`: "100%"};
`);

export const Page: React.FC<PageProps & HeaderProps & BoxProps> = ({
  isMainPage,
  onScrollChange,
  leftIcon,
  onLeftClick,
  leftDisabled,
  center,
  rightIcon,
  onRightClick,
  rightDisabled,
  children,
  paddingTop,
  pt,
  currentStep,
  totalSteps,
  ...props
}) => {
  const [isScrollActive, setScrollActive] = useState(false);
  const [isScrollBottom, setScrollBottom] = useState(false);

  const onScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
    if (onScrollChange && event.target instanceof Element) {
      const isBottom = Math.floor(event.target.scrollHeight - event.target.scrollTop) <= event.target.clientHeight;
      const isActive = !!event.target.scrollTop;

      if (isBottom !== isScrollBottom) {
        setScrollBottom(isBottom);
        onScrollChange(isBottom);
      }

      if (isActive && !isScrollActive) {
        setScrollActive(isActive)
      }
    }
  }

  return (
    <OverflowBox
      position="relative"
      onScroll={onScrollChange ? onScroll : undefined}
      height={onScrollChange ? (isMainPage ? FOOTER_SIZE : "0px") : undefined}>
      <Header
        position="fixed"
        zIndex={1}
        width="100%"
        top={0}
        left={0}
        leftIcon={leftIcon}
        onLeftClick={onLeftClick}
        leftDisabled={leftDisabled}
        center={center}
        rightIcon={rightIcon}
        onRightClick={onRightClick}
        rightDisabled={rightDisabled}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
      <Box
        p={4}
        pt={`calc(${HEADER_SIZE} + ${props.padding ?? props.p ?? paddingTop ?? pt ?? 16}px)`}
        height={isScrollActive ? undefined : "100%"}
        minHeight="100%"
        {...props}>
        {children}
      </Box>
    </OverflowBox>
  );
}
