import React, { useState } from "react";
import { Box, Heading, Text, Image, Alert, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Button, useColorMode, Card, CardBody, SimpleGrid, IconButton, Stack } from "@chakra-ui/react";
import {
  BookingWorkflow,
  OptionInput as OInput,
  NumberPickerInput as NPickerInput,
  TextAreaInput as TAreaInput,
  FileUploadInput as FUploadInput,
  BookingStatus
} from "../models";
import { useTranslation } from "react-i18next";
import { RatingCreator } from "./RatingCreator";
import { BookingMode } from "./BookingEditor";
import { BookingStatusLabel, OptionLineCard, RatingStars, VehicleLineCard } from "app/shared";
import { GLOBAL_NAMESPACE } from "index";
import moment from "moment";
import { ReactComponent as ArrowRightIcon } from "app/shared/icons/ArrowRight.svg";
import { ReactComponent as EditIcon } from "app/shared/icons/Pencil.svg";

interface Props {
  booking: BookingWorkflow;
  mode: BookingMode;
  onStepClick?: (step: number) => void;
}

export const BookingViewer: React.FC<Props> = ({ booking, mode, onStepClick }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const [isRatingModalOpen, setRatingModalOpen] = useState(false);

  const getAlertColor = (status: BookingStatus) => {
    if (status === "Pending") {
      return {
        bg: colorMode === "light" ? "primaryV2Light" : "primaryV2Dark",
        color: colorMode === "light" ? "primaryV1Light" : "primaryV1Dark"
      };
    } else if (status === "Scheduled") {
      return {
        bg: colorMode === "light" ? "alertV1Light" : "alertV1Dark",
        color: colorMode === "light" ? "alertLight" : "alertDark"
      };
    } else if (["Canceled", "Refused", "Deleted"].includes(status)) {
      return {
        bg: colorMode === "light" ? "negativeV1Light" : "negativeV1Dark",
        color: colorMode === "light" ? "negativeLight" : "negativeDark"
      };
    }

    return {
      bg: colorMode === "light" ? "positiveV1Light" : "positiveV1Dark",
      color: colorMode === "light" ? "positiveLight" : "positiveDark"
    };
  }

  const alertColor = getAlertColor(booking.status);

  return (
    <Box>
      {mode === "preview" && (
        <Alert bg={alertColor.bg} justifyContent="center" borderRadius={5}>
          <BookingStatusLabel status={booking.status} color={alertColor.color} />
        </Alert>
      )}
      {["Completed", "Billed"].includes(booking.status) && (
        <Card mt={4}>
          <CardBody>
            {booking.rating ? (
              <>
                <Text variant="h3Strong" mb={4}>{t("bookingViewer.rating")}</Text>
                <RatingStars stars={booking.rating.value} size="15px" />
                <Text variant="h5Regular" my={2}>{booking.rating.comment}</Text>
                <Text variant="h6Regular" colorScheme="primaryV1">
                  {moment(booking.rating.createdAt).format("DD MMM YYYY, HH:mm")}
                </Text>
              </>
            ) : (
              <>
                <Heading variant="h3Strong" mb={4}>{t("bookingViewer.rateTitle")}</Heading>
                <Text variant="h5Regular">{t("bookingViewer.rateDescription")}</Text>
                <Button
                  variant="brand"
                  width="100%"
                  mt={3}
                  rightIcon={<ArrowRightIcon />}
                  iconSpacing="auto"
                  onClick={() => setRatingModalOpen(true)}>
                  {t("bookingViewer.rateTitle")}
                </Button>
              </>
            )}
          </CardBody>
        </Card>
      )}
      <Card mt={4}>
        <CardBody>
          <Heading variant="h3Strong" mb={3}>{t("bookingViewer.vehicle")}</Heading>
          <VehicleLineCard vehicle={booking.vehicle} />
        </CardBody>
      </Card>
      <Card mt={4}>
        <CardBody>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Heading variant="h3Strong">{t("bookingViewer.diagnostic")}</Heading>
            {onStepClick && (
              <IconButton
                variant="ghost"
                justifyContent="flex-end"
                width="16px"
                height="16px"
                aria-label="Edit"
                icon={<EditIcon />}
                sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}
                onClick={() => onStepClick(booking.steps.findIndex(it => it.type === "Input"))}
              />
            )}
          </Stack>
          {booking.steps.filter(step => step.questions.length).map(step => (
            step.questions.filter(question => {
              if (question.type === "NumberPicker") {
                return (question as NPickerInput).defaultValue?.length;
              } else if (question.type === "TextArea") {
                return (question as TAreaInput).defaultValue?.length;
              } else {
                return (question as OInput).defaultValues?.length;
              }
            }).map(question => {
              const values: string[] = [];
              const files: string[] = [];

              if (question.type === "NumberPicker") {
                values.push((question as NPickerInput).defaultValue!!);
              } else if (question.type === "TextArea") {
                values.push((question as TAreaInput).defaultValue!!);
              } else if (question.type === "FileUpload") {
                const stepFileIds = (question as FUploadInput).defaultValues;
                const stepFiles = booking.files.filter(file => stepFileIds?.includes(file.id)).map(file => file.url);
                files.push(...stepFiles);
              } else {
                const optionInput = question as OInput;
                const textValues = (optionInput.defaultValues ?? []).map(optionId => optionInput.options.find(it => it.id === optionId)?.text ?? "");
                values.push(...textValues);
              }

              return (
                <React.Fragment key={question.id}>
                  <Heading variant="h5Strong" colorScheme="primaryV1" mt={3}>{question.title}</Heading>
                  {question.type === "FileUpload" ? (
                    <SimpleGrid columns={3} spacing={2}>
                      {files.map(url => <Image key={url} src={url} width="100%" height={["55px", "100px", "150px"]} objectFit="cover" />)}
                    </SimpleGrid>
                  ) : (
                    <Text variant="h5Regular">{values.length ? values.join(", ") : "-"}</Text>
                  )}
                </React.Fragment>
              )
            }))
          )}
        </CardBody>
      </Card>
      {
        booking.workshop && (
          <Card mt={4}>
            <CardBody>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Heading variant="h3Strong">{t("bookingViewer.workshop")}</Heading>
                {onStepClick && (
                  <IconButton
                    variant="ghost"
                    justifyContent="flex-end"
                    width="16px"
                    height="16px"
                    aria-label="Edit"
                    icon={<EditIcon />}
                    sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}
                    onClick={() => onStepClick(booking.steps.findIndex(it => it.type === "Workshop"))}
                  />
                )}
              </Stack>
              <Text variant="h5Regular">{booking.workshop.name}</Text>
              <Text variant="h6Regular" colorScheme="primaryV1">{[booking.workshop.street, booking.workshop.city, booking.workshop.postalCode, booking.workshop.country].filter(it => it).join(", ")}</Text>
            </CardBody>
          </Card>
        )
      }
      {
        booking.option && (
          <Card mt={4}>
            <CardBody>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Heading variant="h3Strong">{t("bookingViewer.option")}</Heading>
                {onStepClick && (
                  <IconButton
                    variant="ghost"
                    justifyContent="flex-end"
                    width="16px"
                    height="16px"
                    aria-label="Edit"
                    icon={<EditIcon />}
                    sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}
                    onClick={() => onStepClick(booking.steps.findIndex(it => it.type === "Option"))}
                  />
                )}
              </Stack>
              <OptionLineCard option={booking.option} />
              <Text variant="h6Regular" colorScheme="primaryV1" mt={5}>{t("bookingViewer.optionDescription")}</Text>
            </CardBody>
          </Card>
        )
      }
      {
        booking.slot && (
          <Card mt={4}>
            <CardBody>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Heading variant="h3Strong" mb={3}>{t("bookingViewer.slot")}</Heading>
                {onStepClick && (
                  <IconButton
                    variant="ghost"
                    justifyContent="flex-end"
                    width="16px"
                    height="16px"
                    aria-label="Edit"
                    icon={<EditIcon />}
                    sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}
                    onClick={() => onStepClick(booking.steps.findIndex(it => it.type === "Slot"))}
                  />
                )}
              </Stack>
              <Text variant="h5Strong" colorScheme="primaryV1">{t("bookingViewer.date")}</Text>
              <Text variant="h5Regular">{moment(booking.slot.date).format("DD MMM YYYY")}</Text>
              <Text variant="h5Strong" colorScheme="primaryV1" mt={3}>{t("bookingViewer.hour")}</Text>
              <Text variant="h5Regular">{moment(`${booking.slot.date}T${booking.slot.startTime}`).utc().format("HH:mm")} - {moment(`${booking.slot.date}T${booking.slot.endTime}`).utc().format("HH:mm")}</Text>
            </CardBody>
          </Card>
        )
      }
      <Modal isOpen={isRatingModalOpen} onClose={() => setRatingModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {booking.workshop && (
              <RatingCreator
                bookingId={booking.id}
                workshop={booking.workshop}
                close={() => setRatingModalOpen(false)}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box >
  );
}
