import React, { useState } from "react";
import { Box, Button, NumberInput, NumberInputField, Stack, Text, Input, Textarea, Image, IconButton, SimpleGrid, CardBody, Card, useColorMode, NumberDecrementStepper, NumberIncrementStepper, NumberInputStepper } from "@chakra-ui/react";
import {
  OptionInput as OInput,
  NumberPickerInput as NPickerInput,
  FileUploadInput as FUploadInput,
  TextAreaInput as TAreaInput,
  BookingFile,
  OptionInputOption
} from "../models";
import { IconCard, Scrollable } from "app/shared";
import { ReactComponent as CarEmptyIcon } from "app/shared/icons/CarEmpty.svg";
import { ReactComponent as CheckboxOnIcon } from "app/shared/icons/CheckboxOn.svg";
import { ReactComponent as CheckboxOffIcon } from "app/shared/icons/CheckboxOff.svg";
import { ReactComponent as TrashIcon } from "app/shared/icons/Trash.svg";

interface OptionInputProps {
  input: OInput;
  onAnswer: (values: string[]) => void;
}

export const OptionInput: React.FC<OptionInputProps> = ({ input, onAnswer }) => {
  const { colorMode } = useColorMode();
  const [defaultValues, setDefaultValues] = useState<string[]>(input.defaultValues ?? []);

  const onOptionClick = (option: OptionInputOption) => {
    const values = [option.id];
    onAnswer(values);
    setDefaultValues(values);
  }

  return (
    <Box>
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{input.title}{input.required ? " *" : ""}</Text>
      {input.inputType === "List" && (
        <SimpleGrid spacing={4}>
          {input.options.map(option =>
            <Card
              key={option.id}
              borderWidth={1}
              borderColor={defaultValues.includes(option.id) ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}>
              <CardBody>
                <IconCard
                  icon={option.icon}
                  fallbackIcon={<CarEmptyIcon />}
                  iconProps={{ width: "56px", height: "56px" }}
                  onClick={() => onOptionClick(option)}>
                  <Text variant="h5Strong">{option.text}</Text>
                </IconCard>
              </CardBody>
            </Card>
          )}
        </SimpleGrid>
      )}
      {input.inputType === "Scale" && (
        <Scrollable sx={{ "& > :last-child": { mr: 0 } }}>
          {input.options.map(option =>
            <Button
              key={option.id}
              variant="outline"
              mr={2}
              onClick={() => onOptionClick(option)}
              borderColor={defaultValues.includes(option.id) ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}>
              <Text variant="h5Strong">{option.text}</Text>
            </Button>
          )}
        </Scrollable>
      )}
      {input.inputType === "Checkbox" && (
        <SimpleGrid>
          {input.options.map(option =>
            <Button
              key={option.id}
              variant="unstyled"
              display="flex"
              alignItems="center"
              p={0}
              width="fit-content"
              leftIcon={defaultValues.includes(option.id) ? <CheckboxOnIcon /> : <CheckboxOffIcon />}
              onClick={() => onOptionClick(option)}
              borderColor={defaultValues.includes(option.id) ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}>
              <Text variant={defaultValues.includes(option.id) ? "h5Strong" : "h5Regular"}>{option.text}</Text>
            </Button>
          )}
        </SimpleGrid>
      )}
    </Box>
  );
}

interface NumberPickerInputProps {
  input: NPickerInput;
  onAnswer: (values: string[]) => void;
}

export const NumberPickerInput: React.FC<NumberPickerInputProps> = ({ input, onAnswer }) => {
  return (
    <Box>
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{input.title}{input.required ? " *" : ""}</Text>
      <NumberInput
        maxW={120}
        min={input.min}
        max={input.max}
        defaultValue={input.defaultValue}
        onChange={value => {
          onAnswer([value]);
        }}
      >
        <NumberInputField />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </Box>
  );
}

interface FileUploadInputProps {
  input: FUploadInput;
  files: BookingFile[];
  onFileCreate: (file: File) => Promise<BookingFile>;
  onFileDelete: (fileId: string) => Promise<void>;
}

export const FileUploadInput: React.FC<FileUploadInputProps> = ({ input, files, onFileCreate, onFileDelete }) => {
  const { colorMode } = useColorMode();
  const [defaultValues, setDefaultValues] = useState<string[]>(input.defaultValues ?? []);

  return (
    <Box>
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{input.title}{input.required ? " *" : ""}</Text>
      <Stack direction="column">
        <SimpleGrid columns={1} spacing={2} mb={2}>
          {files.filter(file => defaultValues.includes(file.id)).map(file => (
            <Stack key={file.id} direction="row" alignItems="center">
              <Image src={file.url} width="90px" height={["55px", "100px", "150px"]} objectFit="cover" />
              <IconButton
                variant="ghost"
                aria-label="Delete"
                icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "light" ? "negativeLight" : "negativeDark" } }}><TrashIcon /></Box>}
                onClick={async () => {
                  await onFileDelete(file.id);
                  const newValues = defaultValues.filter(fileId => fileId !== file.id);
                  setDefaultValues(newValues);
                }}
              />
            </Stack>
          ))}
        </SimpleGrid>
      </Stack>
      <Input
        type="file"
        accept={input.allowedMimeTypes.join(",")}
        multiple={false}
        max={3}
        onChange={async event => {
          const files = event.target.files;

          if (files?.length) {
            const createdFile = await onFileCreate(files[0]);
            setDefaultValues([...defaultValues, createdFile.id]);
          }
        }}
      />
    </Box>
  );
}

interface TextAreaInputProps {
  input: TAreaInput;
  onAnswer: (values: string[]) => void;
}

export const TextAreaInput: React.FC<TextAreaInputProps> = ({ input, onAnswer }) => {
  return (
    <Box>
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{input.title}{input.required ? " *" : ""}</Text>
      <Textarea
        minLength={input.minChars}
        maxLength={input.maxChars}
        defaultValue={input.defaultValue}
        placeholder={input.hint}
        onChange={event => {
          onAnswer([event.target.value]);
        }}
      />
    </Box>
  );
}
