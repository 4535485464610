import { Stack, StackProps, Heading, Text, Box, useColorMode } from "@chakra-ui/react";
import React, { ReactElement } from "react";

interface Props extends StackProps {
  icon: ReactElement;
  title: string;
  description: string;
}

export const EmptyResults: React.FC<Props> = ({ icon, title, description, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <Stack direction="column" alignItems="center" justifyContent="center" height="100%" {...props}>
      <Box
        as="span"
        sx={{
          "& svg": { width: "auto", height: "75px" },
          "& svg > path": { fill: colorMode === "light" ? "primaryV2Light" : "primaryV2Dark" }
        }}>
        {icon}
      </Box>
      <Heading variant="h3Strong" textAlign="center" mt={2}>{title}</Heading>
      <Text variant="h4Regular" colorScheme="primaryV1" textAlign="center">{description}</Text>
    </Stack>
  );
}
