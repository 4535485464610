import { Stack, StackProps, useColorMode, Box, IconButton } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { StepIndicator } from "./StepIndicator";

export interface HeaderProps {
  leftIcon?: ReactElement;
  onLeftClick?: () => void;
  leftDisabled?: boolean;
  center?: ReactElement;
  rightIcon?: ReactElement;
  onRightClick?: () => void;
  rightDisabled?: boolean;
  currentStep?: number;
  totalSteps?: number;
}

export const Header: React.FC<HeaderProps & StackProps> = ({
  leftIcon,
  onLeftClick,
  leftDisabled,
  center,
  rightIcon,
  onRightClick,
  rightDisabled,
  currentStep,
  totalSteps,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      position="relative"
      bg={colorMode === "light" ? "brandLight" : "brandDark"}
      px={2}
      py={2}
      {...props}>
      <Stack direction="row" alignItems="center">
        <IconButton
          variant="unstyled"
          display="flex"
          justifyContent="center"
          aria-label="Left"
          icon={leftIcon}
          sx={{ "& svg > path": { fill: colorMode === "dark" ? "brandLight" : undefined } }}
          isDisabled={!leftIcon || leftDisabled}
          onClick={onLeftClick}
        />
        <Box display="flex" justifyContent="center" flex={1}>{center}</Box>
        <IconButton
          variant="unstyled"
          display="flex"
          justifyContent="center"
          aria-label="Right"
          icon={rightIcon}
          sx={{ "& svg > path": { fill: colorMode === "dark" ? "brandLight" : undefined } }}
          isDisabled={!rightIcon || rightDisabled}
          onClick={onRightClick}
        />
      </Stack>
      {(currentStep !== undefined && totalSteps !== undefined) && (
        <StepIndicator currentStep={currentStep} totalSteps={totalSteps} position="absolute" bottom={0} left={0} />
      )}
    </Box>
  );
}
