const Modal = {
  variants: {
    full: {
      overlay: {
        _light: {
          background: "white.100"
        }
      },
      body: {
        padding: 0,
        _light: {
          backgroundColor: "backgroundLight.100",
        },
        _dark: {
          backgroundColor: "backgroundDark.100"
        },
      },
      dialog: {
        height: "100%",
        _light: {
          backgroundColor: "backgroundLight.100",
        },
        _dark: {
          backgroundColor: "backgroundDark.100"
        },
      }
    },
    center: {
      dialogContainer: {
        alignItems: "center",
      },
      body: {
        py: 0
      },
      dialog: {
        margin: 0,
        maxWidth: "90%",
        borderRadius: "default2x",
        py: 4,
        background: "secondaryLight",
        _dark: {
          background: "secondaryDark",
        }
      },
      footer: {
        flexDirection: "column"
      }
    },
    bottom: {
      dialogContainer: {
        alignItems: "end"
      },
      dialog: {
        margin: 0,
        maxWidth: "100%",
        borderTopRadius: "default2x",
        borderBottomRadius: 0,
        py: 4,
        background: "secondaryLight",
        _dark: {
          background: "secondaryDark",
        }
      }
    }
  },
  baseStyle: {
  },
  defaultProps: {
    size: "full",
    variant: "full"
  }
};

export default Modal;
