import { Text, Stack, StackProps, Badge, useColorMode, Box, IconButton, Heading, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React, { useState } from "react";
import { ReactComponent as InfoSelectedIcon } from "app/shared/icons/InfoSelected.svg";
import { ReactComponent as TooltipTiresLightIcon } from "app/shared/icons/TooltipTiresLight.svg";
import { ReactComponent as TooltipTiresDarkIcon } from "app/shared/icons/TooltipTiresDark.svg";

interface Props extends StackProps {
  step: number;
  label: string;
  tooltipId?: string;
  tooltipTitle?: string;
}

export const StepLabel: React.FC<Props> = ({ step, label, tooltipId, tooltipTitle, ...props }) => {
  const { colorMode } = useColorMode();
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <Stack direction="row" alignItems="center" {...props}>
        <Badge
          variant="solid"
          background={colorMode === "light" ? "primaryV3Light" : "primaryV3Dark"}
          borderRadius="default"
          px={3}
          py={1}>
            <Text variant="h5Strong">{step}</Text>
          </Badge>
        <Text variant="h3Strong" flex={1}>{label}</Text>
        {tooltipId && (
          <IconButton
            variant="unstyled"
            display="flex"
            justifyContent="end"
            aria-label="Tooltip"
            icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><InfoSelectedIcon /></Box>}
            onClick={() => setTooltipOpen(true)}
          />
        )}
      </Stack>
      {tooltipId && (
        <Modal variant="bottom" size="xs" isOpen={isTooltipOpen} onClose={() => setTooltipOpen(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              {tooltipTitle && <Heading variant="h3Strong" mb={3}>{tooltipTitle}</Heading>}
              <Box display="flex" justifyContent="center">
                {tooltipId === "tires" && (colorMode === "light" ? <TooltipTiresLightIcon /> : <TooltipTiresDarkIcon />)}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
