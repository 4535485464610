import { Text, Stack, StackProps, useColorMode, Box, TextProps } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { ReactComponent as ArrowRightSmallIcon } from "app/shared/icons/ArrowRightSmall.svg";

interface Props extends StackProps {
  icon: ReactElement;
  label: string;
  labelProps?: TextProps;
}

export const LineCard: React.FC<Props> = ({ icon, label, labelProps, onClick, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <Stack direction="row" alignItems="center" px={5} py={4} onClick={onClick} cursor={onClick ? "pointer" : undefined} {...props}>
      <Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}>{icon}</Box>
      <Text variant="h5Regular" flex={1} ml={1} {...labelProps}>{label}</Text>
      {onClick && <Box as="span" sx={{ "& svg path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><ArrowRightSmallIcon /></Box>}
    </Stack>
  );
}
