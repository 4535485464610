import { useColorMode } from "@chakra-ui/react";
import { Auth }  from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ThemeContext } from "theme";

export const Authentication: React.FC = () => {
  const [searchParams] = useSearchParams();
  const { colorMode, setColorMode } = useColorMode();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { themeName, setTheme } = useContext(ThemeContext);

  const username = searchParams.get("username");
  const authChallenge = searchParams.get("authChallenge");
  const newLanguage = searchParams.get("lang");
  const newColorMode = searchParams.get("mode");

  const signIn = async () => {
    try {
      if (username && authChallenge) {
        const user = await Auth.signIn(username);
 
        await Auth.sendCustomChallengeAnswer(user, authChallenge);

        const userCurrentSession = await Auth.currentSession();
        const newTheme = userCurrentSession.getIdToken().payload["cognito:groups"]?.[0];

        if (newTheme && newTheme !== themeName) {
          setTheme(newTheme);
        }
        
        if (newLanguage && newLanguage !== i18n.language) {
          i18n.changeLanguage(newLanguage);
        }
  
        if (newColorMode && newColorMode !== colorMode) {
          setColorMode(newColorMode);
        }

        return navigate("/");
      } else {
        throw new Error("Parameters username and authChallenge are not set")
      }
    } catch (ex) {
      console.error("Error in authentication", ex);
      return navigate("/notfound");
    }
  }

  useEffect(() => {
    signIn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
