const Button = {
  variants: {
    brand: {
      py: "23px",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "19px",
      border: 1,
      borderStyle: "solid",
      borderColor: "brandLight",
      bg: "brandLight",
      _dark: {
        bg: "brandDark",
        borderColor: "brandDark",
        color: "brandLight",
        "& svg > path": {
          fill: "brandLight"
        }
      }
    },
    outline: {
      py: "23px",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "19px",
    },
    solid: {
      bg: "secondaryLight",
      _dark: {
        bg: "primaryV3Dark"
      }
    },
    negative: {
      py: "23px",
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "19px",
      border: 1,
      bg: "negativeLight",
      borderStyle: "solid",
      borderColor: "negativeLight",
      color: "secondaryLight",
      "& svg > path": {
        fill: "secondaryLight"
      },
      _dark: {
        bg: "negativeDark",
        borderColor: "negativeDark",
        color: "secondaryDark",
        "& svg > path": {
          fill: "secondaryDark"
        }
      }
    },
  },
  baseStyle: {
    borderRadius: "default",
  },
  defaultProps: {
    variant: "solid"
  },
};

export default Button;
