import React, { useState } from "react";
import { Heading, Stack, Input, Select, Text, useColorMode } from "@chakra-ui/react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getServices, getVehicles } from "../services";
import { BookingStatus } from "../models";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { GLOBAL_NAMESPACE } from "index";
import { Page } from "app/shared";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";
import { ReactComponent as CorrectIcon } from "app/shared/icons/Correct.svg";
import { ReactComponent as SelectIcon } from "app/shared/icons/Select.svg";

export interface BookingFilters {
  startDate?: string;
  endDate?: string;
  plateNumber?: string;
  status?: BookingStatus;
  serviceId?: string;
}

interface Props {
  defaultFilters?: BookingFilters;
  close: () => void;
  onFiltersChange: (filters: BookingFilters) => void;
}

export const BookingsFilter: React.FC<Props> = ({ defaultFilters, close, onFiltersChange }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();

  const [plateNumber, setPlateNumber] = useState<string | undefined>(defaultFilters?.plateNumber);
  const [serviceId, setServiceId] = useState<string | undefined>(defaultFilters?.serviceId);
  const [status, setStatus] = useState<BookingStatus | undefined>(defaultFilters?.status);
  const [startDate, setStartDate] = useState<string | undefined>(defaultFilters?.startDate);
  const [endDate, setEndDate] = useState<string | undefined>(defaultFilters?.endDate);

  const vehiclesQuery = useInfiniteQuery({
    queryKey: ["vehicles"],
    queryFn: (data) => getVehicles(data.pageParam ?? 0),
    getNextPageParam: (lastPage) => lastPage.links.next ? lastPage.meta.page + 1 : undefined
  });
  const servicesQuery = useQuery({ queryKey: ["services"], queryFn: () => getServices() });

  return (
    <Page
      leftIcon={<CloseIcon />}
      onLeftClick={() => close()}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("bookingsFilter.header")}</Heading>}
      rightIcon={<CorrectIcon />}
      rightDisabled={![startDate, endDate, plateNumber, status, serviceId].some(it => it !== undefined)}
      onRightClick={() => onFiltersChange({ startDate, endDate, plateNumber, status, serviceId })}>
      <Stack direction="column">
        <Text variant="h5Strong" colorScheme="primaryV1">{t("bookingsFilter.plateNumber")}</Text>
        <Select
          icon={<SelectIcon />}
          iconColor={colorMode === "light" ? "primaryLight": "primaryDark"}
          placeholder={t("bookingsFilter.plateNumber") ?? undefined}
          defaultValue={plateNumber}
          onChange={event => setPlateNumber(event.target.value)}>
          {vehiclesQuery.data?.pages?.map((group, key) =>
            <React.Fragment key={key}>
              {group.data?.map(vehicle =>
                <option key={vehicle.plateNumber} value={vehicle.plateNumber}>{vehicle.plateNumber}</option>
              )}
            </React.Fragment>
          )}
        </Select>
        <Text variant="h5Strong" colorScheme="primaryV1" mt={5}>{t("bookingsFilter.service")}</Text>
        <Select
          icon={<SelectIcon />}
          placeholder={t("bookingsFilter.service") ?? undefined}
          defaultValue={serviceId}
          onChange={event => setServiceId(event.target.value)}>
          {servicesQuery.data?.data?.map(service => (
            <option key={service.id} value={service.id}>{service.name}</option>
          ))}
        </Select>
        <Text variant="h5Strong" colorScheme="primaryV1" mt={5}>{t("bookingsFilter.status")}</Text>
        <Select
          icon={<SelectIcon />}
          placeholder={t("bookingsFilter.status") ?? undefined}
          defaultValue={status}
          onChange={event => setStatus(event.target.value as BookingStatus)}>
          <option value="Pending">{t("shared.bookingStatus.pending")}</option>
          <option value="Scheduled">{t("shared.bookingStatus.scheduled")}</option>
          <option value="Canceled">{t("shared.bookingStatus.canceled")}</option>
          <option value="Refused">{t("shared.bookingStatus.refused")}</option>
          <option value="Completed">{t("shared.bookingStatus.completed")}</option>
          <option value="Billed">{t("shared.bookingStatus.billed")}</option>
        </Select>
        <Text variant="h5Strong" colorScheme="primaryV1" mt={5}>{t("bookingsFilter.startDate")}</Text>
        <Input
          type="date"
          defaultValue={startDate}
          max={moment().format("YYYY-MM-DD")}
          onChange={event => setStartDate(event.target.value)}
        />
        <Text variant="h5Strong" colorScheme="primaryV1" mt={5}>{t("bookingsFilter.endDate")}</Text>
        <Input
          type="date"
          defaultValue={endDate}
          min={startDate}
          max={moment().format("YYYY-MM-DD")}
          onChange={event => setEndDate(event.target.value)}
        />
      </Stack>
    </Page>
  );
}
