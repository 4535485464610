import React, { useState } from "react";
import { Heading, Text, Textarea, Input } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { createComplaint } from "../services";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { CreateComplaintCommand } from "../models/commands";
import { GLOBAL_NAMESPACE } from "index";
import { Page } from "app/shared";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";
import { ReactComponent as SendIcon } from "app/shared/icons/Send.svg";

interface Props {
  bookingId?: string;
  close: () => void;
}

export const ComplaintCreator: React.FC<Props> = ({ bookingId, close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);

  const [title, setTitle] = useState<string | undefined>();
  const [description, setDescription] = useState<string | undefined>();

  const createComplaintMutation = useMutation<void, AxiosError<ResponseError>, CreateComplaintCommand>({
    mutationFn: (data) => createComplaint(data, bookingId),
    onSuccess: () => close()
  });

  return (
    <Page
      leftIcon={<ArrowLeftIcon />}
      onLeftClick={() => close()}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("complaintCreator.header")}</Heading>}
      rightIcon={<SendIcon />}
      rightDisabled={title === undefined || description === undefined}
      onRightClick={() => {
        if (title && description) {
          createComplaintMutation.mutate({ title, description });
        }
      }}>
      {createComplaintMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: createComplaintMutation.error.response?.data?.title ?? createComplaintMutation.error.message })}</Text>}
      <Text variant="h5Strong" colorScheme="primaryV1" mb={2}>{t("complaintCreator.title")} *</Text>
      <Input
        type="text"
        onChange={event => setTitle(event.target.value)}
      />
      <Text variant="h5Strong" colorScheme="primaryV1" mt={5} mb={2}>{t("complaintCreator.message")} *</Text>
      <Textarea
        minLength={1}
        onChange={event => setDescription(event.target.value)}
      />
    </Page>
  );
}
