import React, { useRef, useState } from "react";
import { Box, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, SimpleGrid, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SearchInput, Location } from "./SearchInput";
import { Map } from "./Map";
import { useQuery } from "@tanstack/react-query";
import { getWorkshops } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import { Workshop } from "../models";
import { WorkshopViewer } from "./WorkshopViewer";
import { GLOBAL_NAMESPACE } from "index";
import { WorkshopCard } from "app/shared";

interface Props {
  defaultWorkshopId?: string;
  defaultLocation?: Location;
  services: string[];
  categories: string[];
  onWorkshopSelected: (workshopId?: string) => void;
  onLocationSelected: (location: Location) => void;
}

export const LocationSelector: React.FC<Props> = ({ defaultWorkshopId, defaultLocation, services, categories, onWorkshopSelected, onLocationSelected }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();

  const [selectedWorkshopId, setSelectedWorkshopId] = useState<string | undefined>(defaultWorkshopId);
  const [center, setCenter] = useState({ latitude: defaultLocation?.latitude ?? 38.7436863, longitude: defaultLocation?.longitude ?? -9.2014027 });
  const [selectedModalWorkshop, setSelectedModalWorkshop] = useState<Workshop | undefined>();
  const searchTerms = useRef("");

  const query = useQuery<Workshop[], AxiosError<ResponseError>>({
    queryKey: ["workshops", center, services, categories],
    queryFn: () => getWorkshops({ center, services, categories })
  });

  const onLocationClick = (newLocation: Location) => {
    onLocationSelected(newLocation);
    setCenter({ latitude: newLocation.latitude, longitude: newLocation.longitude });
  }
  const onWorkshopClick = (workshop: Workshop) => {
    onWorkshopSelected(workshop.id);
    setSelectedWorkshopId(workshop.id);
  }

  return (
    <Box>
      <Heading variant="h5Regular" mb={5}>{t("locationSelector.locationDescription")}</Heading>
      <Text variant="h5Regular" colorScheme="primaryV1" mb={1}>{t("locationSelector.searchPlaceholder")} *</Text>
      <SearchInput
        onSearch={(terms, results) => {
          if (results.length) {
            onLocationClick({ ...results[0], address: terms });
          }
          searchTerms.current = terms;
        }}
      />
      {searchTerms.current.length > 0 && (
        <>
          <Box height="400px" my={3}>
            <Map
              showCenter={true}
              center={center}
              zoom={12}
              markers={query.data?.map(workshop => ({
                id: workshop.id,
                position: { latitude: workshop.latitude, longitude: workshop.longitude }
              })) ?? []}
              onMarkerClick={marker => {
                const workshop = query.data?.find(workshop => workshop.id === marker.id);

                if (workshop) {
                  onWorkshopClick(workshop);
                }
              }}
              onMapClick={position => {
                onLocationClick({ ...position, address: searchTerms.current });

                // Reset selected workshop
                onWorkshopSelected(undefined);
                setSelectedWorkshopId(undefined);
              }}
            />
          </Box>
          <SimpleGrid spacing={2}>
            {query.data?.map(workshop =>
              <WorkshopCard
                key={workshop.id}
                workshop={workshop}
                onClick={() => onWorkshopClick(workshop)}
                onInfoClick={() => setSelectedModalWorkshop(workshop)}
                borderWidth={1}
                borderColor={workshop.id === selectedWorkshopId ? (colorMode === "light" ? "primaryLight" : "primaryDark") : (colorMode === "light" ? "primaryV2Light" : "primaryV2Dark")}
              />
            )}
          </SimpleGrid>
          <Modal isOpen={selectedModalWorkshop !== undefined} onClose={() => setSelectedModalWorkshop(undefined)}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                {selectedModalWorkshop && <WorkshopViewer workshop={selectedModalWorkshop} close={() => setSelectedModalWorkshop(undefined)} disableLinks={true} />}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}
