import { Text, useColorMode, Card, CardBody, CardProps, Stack, Box, StackDivider, IconButton } from "@chakra-ui/react";
import { Workshop } from "app/bookings/models";
import React from "react";
import { ReactComponent as InfoIcon } from "app/shared/icons/Info.svg";
import { RatingLabel } from "./RatingLabel";

interface Props extends CardProps {
  workshop: Workshop;
  onClick: () => void;
  onInfoClick: () => void;
}

export const WorkshopCard: React.FC<Props> = ({ workshop, onClick, onInfoClick, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <Card {...props}>
      <CardBody>
        <Stack direction="row" alignItems="center" divider={<StackDivider />}>
          <Box cursor="pointer" flex={1} overflow="hidden" onClick={onClick} sx={{ "& > *": { overflow: "hidden", textOverflow: "ellipsis" } }}>
            <Text variant="h4Strong" whiteSpace="nowrap">
              {workshop.name}
              </Text>
            <RatingLabel rating={workshop.rating} />
          </Box>
          <IconButton
            variant="ghost"
            aria-label="Info"
            icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "light" ? "primaryV1Light" : "primaryV1Dark" } }}><InfoIcon /></Box>}
            onClick={onInfoClick}
          />
        </Stack>
      </CardBody>
    </Card>
  );
}
