import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";

interface Props extends BoxProps {}

export const Scrollable: React.FC<Props> = ({ children, ...props }) => {
  return (
    <Box
      whiteSpace="nowrap"
      overflowX="auto"
      overflowY="hidden"
      {...props}
    >
      {children}
    </Box>
  );
}
