import { Box, Stack, StackProps, useColorMode } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as StarEmptyIcon } from "app/shared/icons/StarEmpty.svg";
import { ReactComponent as StarHalfIcon } from "app/shared/icons/StarHalf.svg";
import { ReactComponent as StarFullIcon } from "app/shared/icons/StarFull.svg";

interface Props extends StackProps {
  stars: number;
  size?: string;
  onStarClick?: (stars: number) => void;
}

export const RatingStars: React.FC<Props> = ({ stars, size, onStarClick, ...props }) => {
  const { colorMode } = useColorMode();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        "& svg": { width: size ?? "12px", height: size ?? "12px" },
        "& svg > path": { fill: colorMode === "dark" ? "alertDark" : undefined }
      }}
      {...props}>
      <Box cursor={onStarClick ? "pointer" : undefined} onClick={() => onStarClick?.(1)}>
        {stars >= 1 ? <StarFullIcon /> : (stars >= 0.5 ? <StarHalfIcon /> : <StarEmptyIcon />) }
      </Box>
      <Box cursor={onStarClick ? "pointer" : undefined} onClick={() => onStarClick?.(2)}>
        {stars >= 2 ? <StarFullIcon /> : (stars >= 1.5 ? <StarHalfIcon /> : <StarEmptyIcon />) }
      </Box>
      <Box cursor={onStarClick ? "pointer" : undefined} onClick={() => onStarClick?.(3)}>
        {stars >= 3 ? <StarFullIcon /> : (stars >= 2.5 ? <StarHalfIcon /> : <StarEmptyIcon />) }
      </Box>
      <Box cursor={onStarClick ? "pointer" : undefined} onClick={() => onStarClick?.(4)}>
        {stars >= 4 ? <StarFullIcon /> : (stars >= 3.5 ? <StarHalfIcon /> : <StarEmptyIcon />) }
      </Box>
      <Box cursor={onStarClick ? "pointer" : undefined} onClick={() => onStarClick?.(5)}>
        {stars >= 5 ? <StarFullIcon /> : (stars >= 4.5 ? <StarHalfIcon /> : <StarEmptyIcon />) }
      </Box>
    </Stack>
  );
}
