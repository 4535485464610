import environment from "configurations";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const SUPPORTED_LANGUAGES = {
  pt: "pt",
  en: "en"
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !environment.production,
    fallbackLng: SUPPORTED_LANGUAGES.en,
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {},
  });

/**
 * Register a new resource bundle containing translations for a specific language.
 * The resource bundle is a JSON object where the keys are the translation keys and the values are the translations.
 * These resource bundles are scoped to a sepecific namespace
 * @param language
 * @param namespace
 * @param resourceBundle
 */
export const registerResourceBundle = (
  language: string = SUPPORTED_LANGUAGES.pt,
  namespace: string,
  resourceBundle: any
) => {
  i18n.addResourceBundle(language, namespace, resourceBundle);
};

export default i18n;
