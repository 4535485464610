import { fontsStyle } from "./Text";

const Select = {
  variants: {
    outline: {
      field: {
        ...fontsStyle.h5Regular,
        bg: "primaryV2Light",
        _dark: {
          bg: "primaryV2Dark",
          "& > option, & > optgroup": {
            bg: "primaryV3Dark"
          }
        },
        _hover: {
          borderColor: "primaryLight",
          _dark: {
            borderColor: "primaryDark",
          }
        },
        _focusVisible: {
          borderColor: "primaryLight",
          _dark: {
            borderColor: "primaryDark"
          }
        }
      },
      icon: {
        width: "14px",
        insetEnd: "16px",
        "& > svg path": {
          _dark: {
            fill: "primaryDark"
          }
        }
      }
    }
  },
  baseStyle: {
  },
  defaultProps: {
    variant: "outline"
  },
};

export default Select;
