import { Text, useColorMode, Card, CardBody, CardProps, Stack, Box, Heading } from "@chakra-ui/react";
import { Service } from "app/bookings/models";
import React from "react";
import { ReactComponent as AccidentSmallIcon } from "app/shared/icons/AccidentSmall.svg";
import { ReactComponent as AirConditioningSmallIcon } from "app/shared/icons/AirConditioningSmall.svg";
import { ReactComponent as BatterySmallIcon } from "app/shared/icons/BatterySmall.svg";
import { ReactComponent as BreakSmallIcon } from "app/shared/icons/BreakSmall.svg";
import { ReactComponent as CheckupSmallIcon } from "app/shared/icons/CheckupSmall.svg";
import { ReactComponent as TireSmallIcon } from "app/shared/icons/TireSmall.svg";
import { ReactComponent as WashSmallIcon } from "app/shared/icons/WashSmall.svg";
import { ReactComponent as AccidentLargeIcon } from "app/shared/icons/AccidentLarge.svg";
import { ReactComponent as AirConditioningLargeIcon } from "app/shared/icons/AirConditioningLarge.svg";
import { ReactComponent as BatteryLargeIcon } from "app/shared/icons/BatteryLarge.svg";
import { ReactComponent as BreakLargeIcon } from "app/shared/icons/BreakLarge.svg";
import { ReactComponent as CheckupLargeIcon } from "app/shared/icons/CheckupLarge.svg";
import { ReactComponent as TireLargeIcon } from "app/shared/icons/TireLarge.svg";
import { ReactComponent as WashLargeIcon } from "app/shared/icons/WashLarge.svg";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";

export const servicesMap = [
  {
    names: ["Pneu", "6551889c-a463-4379-8330-1c925d09c920", "6551889c-a463-4379-8330-1c925d09c920", "b73cf6f1-d81b-430a-a306-3f916ff8c60b"],
    iconSmall: TireSmallIcon,
    iconLarge: TireLargeIcon,
    label: "shared.service.tire",
    description: "shared.serviceCard.tireDescription"
  },
  {
    names: ["Bateria", "c39e6ed1-12a1-434f-a02d-589beda01594", "c39e6ed1-12a1-434f-a02d-589beda01594", "98012235-1bae-4d15-ad5f-9ac265fe116d"],
    iconSmall: BatterySmallIcon,
    iconLarge: BatteryLargeIcon,
    label: "shared.service.battery",
    description: "shared.serviceCard.batteryDescription"
  },
  {
    names: ["Avaria", "492665f9-3bbc-44c0-82ed-b0c85c5cb7c9", "492665f9-3bbc-44c0-82ed-b0c85c5cb7c9", "f3621c10-668d-42ca-bf6d-b47c0223f403"],
    iconSmall: BreakSmallIcon,
    iconLarge: BreakLargeIcon,
    label: "shared.service.break",
    description: "shared.serviceCard.breakDescription"
  },
  {
    names: ["Revisão", "810d0fc6-0182-4318-9b08-f69060b07d2a", "810d0fc6-0182-4318-9b08-f69060b07d2a", "470a6ee6-088d-40a8-97ae-6410563f6ae4"],
    iconSmall: CheckupSmallIcon,
    iconLarge: CheckupLargeIcon,
    label: "shared.service.checkup",
    description: "shared.serviceCard.checkupDescription"
  },
  {
    names: ["Ar Condicionado", "6873997d-975d-4830-b2fe-aca3549ce97e", "6873997d-975d-4830-b2fe-aca3549ce97e", "c763e8c8-3447-4839-8903-c566a164c8d4"],
    iconSmall: AirConditioningSmallIcon,
    iconLarge: AirConditioningLargeIcon,
    label: "shared.service.airConditioning",
    description: "shared.serviceCard.airConditioningDescription"
  },
  {
    names: ["Acidente", "01c9983b-0b75-46bb-b650-d4bb660022ec", "01c9983b-0b75-46bb-b650-d4bb660022ec", "91cce163-6699-4d38-84ef-7885bcd3b126"],
    iconSmall: AccidentSmallIcon,
    iconLarge: AccidentLargeIcon,
    label: "shared.service.accident",
    description: "shared.serviceCard.accidentDescription"
  },
  {
    names: ["Lavagem", "349be1db-f2d2-4a82-a3e4-bbf9ca9b2e88", "349be1db-f2d2-4a82-a3e4-bbf9ca9b2e88", "85008ec1-31b9-4416-b22f-b4aa32cb3de7"],
    iconSmall: WashSmallIcon,
    iconLarge: WashLargeIcon,
    label: "shared.service.wash",
    description: "shared.serviceCard.washDescription"
  },
];

interface Props extends CardProps {
  service: Service;
}

export const ServiceCard: React.FC<Props> = ({ service, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const selectedService = servicesMap.find(it => it.names.includes(service.name) || it.names.includes(service.id));

  return (
    <Card direction="row" {...props}>
      {selectedService && (
        <Box display="flex" py={5} pl={5} alignItems="center" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}>
          {<selectedService.iconLarge />}
        </Box>
      )}
      <Stack>
        <CardBody>
          <Heading variant="h4Strong">{selectedService ? t(selectedService.label) : service.name}</Heading>
          {selectedService && <Text variant="h6Regular" colorScheme="primaryV1">{t(selectedService.description)}</Text>}
        </CardBody>
      </Stack>
    </Card>
  );
}
