const Card = {
  variants: {
  },
  baseStyle: {
    container: {
      bg: "white.100",
      _dark: {
        background: "primaryV3Dark"
      }
    }
  },
  defaultProps: {
  },
};

export default Card;
