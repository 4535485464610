import { Heading, Text, Card, CardBody, CardProps, Stack, Box, useColorMode } from "@chakra-ui/react";
import { NotificationListItem } from "app/bookings/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { servicesMap } from "./ServiceCard";
import moment from "moment";
import { ReactComponent as BadgeIcon } from "app/shared/icons/Badge.svg";
import { ReactComponent as ClockIcon } from "app/shared/icons/Clock.svg";
import { GLOBAL_NAMESPACE } from "index";

interface Props extends CardProps {
  notification: NotificationListItem;
}

export const NotificationCard: React.FC<Props> = ({ notification, ...props }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const selectedService = servicesMap.find(it => it.names.includes(notification.args.serviceId));

  return (
    <Card {...props}>
      <CardBody>
        <Stack direction="row" alignItems="center">
          {!notification.read && <Box as="span" mr={1}><BadgeIcon /></Box>}
          <Heading variant="h4Strong">{notification.shortDescription}</Heading>
        </Stack>
        <Text variant="h5Regular">{selectedService ? t(selectedService.label) : notification.args.serviceId}: {notification.args.plateNumber}</Text>
        <Text display="flex" alignItems="center" variant="h6Regular" colorScheme="primaryV1">
          <Box
            as="span"
            sx={{ "& svg > path": { fill: colorMode === "light" ? "primaryV1Light" : "primaryV1Dark" } }}>
            <ClockIcon width="9px" />
          </Box>
          <Box as="span" ml={1}>{moment(notification.createdAt).fromNow()}</Box>
        </Text>
      </CardBody>
    </Card>
  );
}
