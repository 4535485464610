import axios, { PagedResponse } from "network";
import { Booking, BookingFile, BookingListItem, BookingStatus, BookingWorkflow, NotificationListItem, NotificationsCounter, Option, Service, SignedUrl, Slot, UserNotificationListItem, Vehicle, WorkflowStep, Workshop } from "../models";
import { CreateBookingCommand, CreateComplaintCommand, CreateRatingCommand, UpdateAnswerCommand, UpdateBookingCommand, UpdateNotificationSettingsCommand } from "../models/commands";

export const getServices = async (page: number = 0, size: number = 50) => {
  return (await axios.get<PagedResponse<Service>>(`/public/app/services?page[number]=${page}&page[size]=${size}`)).data;
};

export const getServiceSteps = async (serviceId: string, lang: string) => {
  return (await axios.get<WorkflowStep[]>(`/app/bookings/services/${serviceId}/steps`, { headers: { "Accept-Language": lang.split("-")[0] } })).data;
};

export const getVehicles = async (page: number, size: number = 20) => {
  return (await axios.get<PagedResponse<Vehicle>>(`/app/vehicles?page[number]=${page}&page[size]=${size}`)).data;
};

export const createVehicle = async (plateNumber: string) => {
  return (await axios.post<Vehicle>(`/app/vehicles`, { plateNumber })).data;
};

export const updateVehicle = async (vehicleId: string, status: "Active") => {
  return (await axios.put<Vehicle>(`/app/vehicles/${vehicleId}`, { status })).data;
};

export const deleteVehicle = async (vehicleId: string) => {
  return (await axios.delete<void>(`/app/vehicles/${vehicleId}`)).data;
};

export const getBookings = async (
  filters: {
    page: number;
    size?: number;
    startDate?: string;
    endDate?: string;
    plateNumber?: string;
    status?: BookingStatus;
    serviceId?: string;
    categoryId?: string;
  }
) => {
  const params = [
    `page[number]=${filters.page}`,
    `page[size]=${filters.size ?? 20}`
  ];

  if (filters.startDate?.length) {
    params.push(`filter[startDate]=${filters.startDate}`);
  }

  if (filters.endDate?.length) {
    params.push(`filter[endDate]=${filters.endDate}`);
  }

  if (filters.plateNumber?.length) {
    params.push(`filter[plateNumber]=${filters.plateNumber}`);
  }

  if (filters.status?.length) {
    params.push(`filter[status]=${filters.status}`);
  }

  if (filters.serviceId?.length) {
    params.push(`filter[service]=${filters.serviceId}`);
  }

  if (filters.categoryId?.length) {
    params.push(`filter[service]=${filters.categoryId}`);
  }

  return (await axios.get<PagedResponse<BookingListItem>>(`/app/bookings?${params.join("&")}`)).data;
};

export const createBooking = async (cmd: CreateBookingCommand) => {
  return (await axios.post<Booking>(`/app/bookings`, cmd)).data;
};

export const getBooking = async (id: string, lang: string) => {
  return (await axios.get<BookingWorkflow>(`/app/bookings/${id}/workflows`, { headers: { "Accept-Language": lang.split("-")[0] } })).data;
};

export const updateBooking = async (bookingId: string, cmd: UpdateBookingCommand) => {
  return (await axios.put<Booking>(`/app/bookings/${bookingId}`, cmd)).data;
};

export const updateBookingAnswer = async (bookingId: string, stepId: string, cmd: UpdateAnswerCommand[], lang: string) => {
  return (await axios.put<BookingWorkflow>(`/app/bookings/${bookingId}/workflows/steps/${stepId}/answer`, cmd, { headers: { "Accept-Language": lang.split("-")[0] } })).data;
};

export const createBookingFile = async (bookingId: string, stepId: string, questionId: string, file: File) => {
  return (await axios.post<BookingFile>(`/app/bookings/${bookingId}/workflows/steps/${stepId}/questions/${questionId}/files`, file, { headers: { "Content-Type": "binary/octet-stream" } })).data;
};

export const deleteBookingFile = async (bookingId: string, stepId: string, questionId: string, fileId: string) => {
  return (await axios.delete<void>(`/app/bookings/${bookingId}/workflows/steps/${stepId}/questions/${questionId}/files/${fileId}`)).data;
};

export const getWorkshops = async (
  filters: {
    center?: { latitude: number; longitude: number };
    services?: string[];
    categories?: string[];
    perks?: string[];
    terms?: string;
  }
) => {
  const params = [
    `region[center]=${filters.center?.latitude ?? 38.7139314},${filters.center?.longitude ?? -9.1514433}`
  ];

  if (filters.services?.length) {
    params.push(`services=${filters.services.join(",")}`);
  }

  if (filters.categories?.length) {
    params.push(`categories=${filters.categories.join(",")}`);
  }

  if (filters.perks?.length) {
    params.push(`perks=${filters.perks.join(",")}`);
  }

  if (filters.terms?.length) {
    params.push(`terms=${filters.terms}`);
  }

  return (await axios.get<Workshop[]>(`/public/app/workshops?${params.join("&")}`)).data;
};

export const getBookingOptions = async (id: string) => {
  return (await axios.get<Option[]>(`/app/bookings/${id}/options`)).data;
};

export const getBookingSlots = async (id: string, startDate: string, endDate: string) => {
  return (await axios.get<Slot[]>(`/app/bookings/${id}/slots?startDate=${startDate}&endDate=${endDate}`)).data;
};

export const submitBooking = async (id: string) => {
  return (await axios.post<Booking>(`/app/bookings/${id}/submit`)).data;
};

export const deleteBooking = async (id: string) => {
  return (await axios.delete<void>(`/app/bookings/${id}`)).data;
};

export const getBookingInvoice = async (id: string) => {
  return (await axios.get<SignedUrl>(`/app/bookings/${id}/invoice`)).data;
};

export const createBookingRating = async (id: string, cmd: CreateRatingCommand) => {
  return (await axios.post<void>(`/app/bookings/${id}/rating`, cmd)).data;
};

export const getNotificationsCounter = async () => {
  return (await axios.get<NotificationsCounter>(`/app/notificationsCounter`)).data;
};

export const getNotifications = async (page: number, size: number = 20) => {
  return (await axios.get<PagedResponse<NotificationListItem>>(`/app/notifications?page[number]=${page}&page[size]=${size}`)).data;
};

export const markAllNotificationsRead = async () => {
  return (await axios.post<void>(`/app/notifications/markAllRead`)).data;
};

export const getNotificationSettings = async () => {
  return (await axios.get<UserNotificationListItem[]>(`/app/notificationSettings`)).data;
};

export const updateNotificationSettings = async (cmd: UpdateNotificationSettingsCommand) => {
  return (await axios.put<UserNotificationListItem[]>(`/app/notificationSettings`, cmd)).data;
};

export const createComplaint = async (cmd: CreateComplaintCommand, bookingId?: string) => {
  return (await axios.post<void>(`/app/complaints${bookingId ? `?booking=${bookingId}` : ""}`, cmd)).data;
};

export const getChatSession = async (bookingId?: string) => {
  return (await axios.get<SignedUrl>(`/app/chatSessions${bookingId ? `?booking=${bookingId}` : ""}`)).data;
};
