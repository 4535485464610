import React, { useEffect, useState, useRef } from "react";
import { Text, Button, Stack, useToast, Modal, ModalBody, ModalContent, ModalOverlay, Heading, useColorMode, Box, Card, CardBody, IconButton, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Checkbox } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getBooking, updateBookingAnswer, updateBooking, submitBooking, deleteBooking, createBookingFile, deleteBookingFile, getBookingInvoice } from "../services";
import { AxiosError } from "axios";
import { ResponseError } from "network";
import {
  BookingWorkflow,
  Booking as BookingResponse,
  OptionInput as OInput,
  NumberPickerInput as NPickerInput,
  TextAreaInput as TAreaInput,
  BookingFile,
  WorkflowStep as Wstep,
  SignedUrl
} from "../models";
import { WorkflowStep } from "./WorkflowStep";
import { UpdateAnswerCommand, UpdateBookingCommand } from "../models/commands";
import { } from "../models";
import { useTranslation } from "react-i18next";
import { Location } from "./SearchInput";
import { BookingViewer } from "./BookingViewer";
import { ComplaintCreator } from "./ComplaintCreator";
import { GLOBAL_NAMESPACE } from "index";
import { Page, LineCard, StepLabel } from "app/shared";
import { servicesMap } from "app/shared/ServiceCard";
import { ReactComponent as CloseIcon } from "app/shared/icons/Close.svg";
import { ReactComponent as HorizontalDotsIcon } from "app/shared/icons/HorizontalDots.svg";
import { ReactComponent as DownloadIcon } from "app/shared/icons/Download.svg";
import { ReactComponent as TrashIcon } from "app/shared/icons/Trash.svg";
import { ReactComponent as CanceledIcon } from "app/shared/icons/Canceled.svg";
import { ReactComponent as ContinueIcon } from "app/shared/icons/Continue.svg";
import { ReactComponent as ComplaintIcon } from "app/shared/icons/Complaint.svg";
import { ReactComponent as HelpIcon } from "app/shared/icons/Help.svg";
import { ReactComponent as ArrowLeftSmallIcon } from "app/shared/icons/ArrowLeftSmall.svg";
import { ReactComponent as ArrowRightIcon } from "app/shared/icons/ArrowRight.svg";
import { ReactComponent as CorrectIcon } from "app/shared/icons/Correct.svg";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";
import { Help } from "./Help";

export type BookingMode = "preview" | "workflow";

interface AnswerMutationCommand {
  bookingId: string;
  stepId: string;
  answers: UpdateAnswerCommand[];
}

interface CreateFileMutationCommand {
  bookingId: string;
  stepId: string;
  questionId: string;
  file: File;
}

interface DeleteFileMutationCommand {
  bookingId: string;
  stepId: string;
  questionId: string;
  fileId: string;
}

interface Props {
  bookingId: string;
  close: () => void;
  defaultMode: BookingMode;
}

export const BookingEditor: React.FC<Props> = ({ bookingId, close, defaultMode }) => {
  const { t, i18n } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const toast = useToast();

  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<UpdateAnswerCommand[]>([]);
  const [workshopId, setWorkshopId] = useState<string | undefined>();
  const [location, setLocation] = useState<Location | undefined>();
  const [optionId, setOptionId] = useState<string | undefined>();
  const [slotId, setSlotId] = useState<string | undefined>();
  const [isActionsModalOpen, setActionsModalOpen] = useState(false);
  const [isComplaintModalOpen, setComplaintModalOpen] = useState(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [mode, setMode] = useState(defaultMode);
  const [saveBooking, setSaveBooking] = useState(true);
  const saveDialog = useDisclosure();
  const saveDialogCancelRef = useRef<HTMLButtonElement>(null);

  const query = useQuery<BookingWorkflow, AxiosError<ResponseError>>({ queryKey: ["bookings", bookingId], queryFn: () => getBooking(bookingId, i18n.language) });
  const saveAnswerMutation = useMutation<BookingWorkflow, AxiosError<ResponseError>, AnswerMutationCommand>({
    mutationFn: (data) => updateBookingAnswer(data.bookingId, data.stepId, data.answers, i18n.language),
    onSuccess: (workflow) => {
      queryClient.setQueryData<BookingWorkflow>(["bookings", bookingId], workflow);
      setStep(step + 1);
    }
  });
  const createFileMutation = useMutation<BookingFile, AxiosError<ResponseError>, CreateFileMutationCommand>({
    mutationFn: (data) => createBookingFile(data.bookingId, data.stepId, data.questionId, data.file),
    onSuccess: (file) => {
      queryClient.setQueryData<BookingWorkflow>(["bookings", bookingId], (previous) => {
        if (previous) {
          return {
            ...previous,
            files: [...previous.files, file]
          };
        }

        return previous;
      });
    }
  });
  const deleteFileMutation = useMutation<void, AxiosError<ResponseError>, DeleteFileMutationCommand>({
    mutationFn: (data) => deleteBookingFile(data.bookingId, data.stepId, data.questionId, data.fileId),
    onSuccess: (_, variables) => {
      queryClient.setQueryData<BookingWorkflow>(["bookings", bookingId], (previous) => {
        if (previous) {
          const files = previous.files.filter(file => file.id !== variables.fileId);

          return { ...previous, files };
        }

        return previous;
      });
    }
  });
  const updateBookingMutation = useMutation<BookingResponse, AxiosError<ResponseError>, UpdateBookingCommand>({
    mutationFn: (data) => updateBooking(bookingId, data),
    onSuccess: (booking) => {
      queryClient.setQueryData<BookingWorkflow>(["bookings", bookingId], (previous) => {
        if (previous) {
          return {
            ...previous,
            workshop: booking.workshop,
            address: booking.address,
            latitude: booking.latitude,
            longitude: booking.longitude,
            option: booking.option,
            slot: booking.slot
          };
        }

        return previous;
      });
      setStep(step + 1);
    }
  });
  const submitMutation = useMutation<BookingResponse, AxiosError<ResponseError>>({
    mutationFn: () => submitBooking(bookingId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bookings"] });
      toast({
        title: t("bookingEditor.submitSuccessToast"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      close();
    }
  });
  const downloadMutation = useMutation<SignedUrl, AxiosError<ResponseError>>({
    mutationFn: () => getBookingInvoice(bookingId),
    onSuccess: (signedUrl) => {
      window.open(signedUrl.url);
    }
  });
  const deleteMutation = useMutation<void, AxiosError<ResponseError>>({
    mutationFn: () => deleteBooking(bookingId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bookings"] });
      close();
    }
  });

  const onAnswer = (questionId: string, values: string[]) => {
    const newAnswers = answers.filter(answer => answer.questionId !== questionId);
    setAnswers([...newAnswers, { questionId, values }]);
  }

  const validateStep = (step: Wstep) => {
    if (step.type === "Input") {
      return !step.questions.some(question => {
        if (question.required) {
          const answer = answers.find(answer => answer.questionId === question.id);

          if (answer === undefined || answer.values.length === 0) {
            return true;
          }
        }

        return false;
      });
    } else if (step.type === "Workshop" && workshopId !== undefined) {
      return true;
    } else if (step.type === "Location" && workshopId !== undefined && location !== undefined) {
      return true;
    } else if (step.type === "Option" && optionId !== undefined) {
      return true;
    } else if (step.type === "Slot" && slotId !== undefined) {
      return true;
    }

    return false;
  }

  const onSubmit = (step: Wstep) => {
    if (step.type === "Input") {
      saveAnswerMutation.mutate({
        bookingId,
        stepId: step.id,
        answers
      });
    } else if (step.type === "Workshop" && workshopId !== undefined) {
      updateBookingMutation.mutate({ workshopId });
    } else if (step.type === "Location" && workshopId !== undefined && location !== undefined) {
      updateBookingMutation.mutate({
        workshopId,
        address: location.address,
        latitude: location.latitude,
        longitude: location.longitude
      });
    } else if (step.type === "Option" && optionId !== undefined) {
      updateBookingMutation.mutate({ optionId });
    } else if (step.type === "Slot" && slotId !== undefined) {
      updateBookingMutation.mutate({ slotId });
    }
  }

  const onClose = () => {
    if (saveBooking) {
      close();
    } else {
      deleteMutation.mutate();
    }
  }

  // When booking is loaded and step changes update answers
  useEffect(() => {
    if (!query.isLoading && query.data) {
      const newCurrentStep = query.data?.steps?.[step];

      if (newCurrentStep) {
        const answers = newCurrentStep.questions
          .filter(question => {
            if (question.type === "NumberPicker") {
              return (question as NPickerInput).defaultValue?.length;
            } else if (question.type === "TextArea") {
              return (question as TAreaInput).defaultValue?.length;
            } else {
              return (question as OInput).defaultValues?.length;
            }
          })
          .map(question => {
            const defaultValues: string[] = [];

            if (question.type === "NumberPicker") {
              defaultValues.push((question as NPickerInput).defaultValue!!);
            } else if (question.type === "TextArea") {
              defaultValues.push((question as NPickerInput).defaultValue!!);
            } else {
              defaultValues.push(...(question as OInput).defaultValues!!);
            }

            return {
              questionId: question.id,
              values: defaultValues
            };
          });
        setAnswers(answers);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, query.isLoading]);

  const maxSteps = (query.data?.steps?.length ?? 1) + 1;
  const currentStep = query.data?.steps?.[step];
  const isEditMode = query.data && query.data.status === "Pending" && mode === "workflow" && currentStep;
  const isSubmitMode = query.data && query.data.status === "Pending" && mode === "workflow" && !currentStep;
  const isPreviewMode = query.data && mode === "preview";

  return (
    <Page
      leftIcon={isPreviewMode ? <ArrowLeftIcon /> : <CloseIcon />}
      onLeftClick={() => isPreviewMode ? close() : saveDialog.onOpen()}
      center={<Heading variant="h5Strong" colorScheme="brand">{query.data ? t(servicesMap.find(it => it.names.includes(query.data.service.id))!.label) : ""}</Heading>}
      rightIcon={isPreviewMode ? <HorizontalDotsIcon /> : <HelpIcon />}
      onRightClick={() => isPreviewMode ? setActionsModalOpen(true) : setHelpModalOpen(true)}
      currentStep={(isEditMode || isSubmitMode) ? step + 2 : undefined}
      totalSteps={(isEditMode || isSubmitMode) ? (query.data?.steps?.length ?? 0) + 2 : undefined}>
      {query.isFetching && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>}
      {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
      {saveAnswerMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: saveAnswerMutation.error.response?.data?.title ?? saveAnswerMutation.error.message })}</Text>}
      {updateBookingMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: updateBookingMutation.error.response?.data?.title ?? updateBookingMutation.error.message })}</Text>}
      {submitMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: submitMutation.error.response?.data?.title ?? submitMutation.error.message })}</Text>}
      {downloadMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: downloadMutation.error.response?.data?.title ?? downloadMutation.error.message })}</Text>}
      {deleteMutation.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: deleteMutation.error.response?.data?.title ?? deleteMutation.error.message })}</Text>}
      {isEditMode && (
        <Box display="flex" flexDirection="column" height="100%">
          <Card>
            <CardBody>
              <StepLabel
                step={step + 2}
                label={currentStep.title}
                tooltipId={currentStep.tooltipId}
                tooltipTitle={t("bookingEditor.tooltipTitleTires") ?? ""}
                mb={4}
              />
              <WorkflowStep
                booking={query.data}
                step={currentStep}
                onAnswer={onAnswer}
                onFileCreate={(questionId, file) => createFileMutation.mutateAsync({ bookingId, stepId: currentStep.id, questionId, file })}
                onFileDelete={(questionId, fileId) => deleteFileMutation.mutateAsync({ bookingId, stepId: currentStep.id, questionId, fileId })}
                onWorkshopSelected={workshopId => setWorkshopId(workshopId)}
                onOptionSelected={optionId => setOptionId(optionId)}
                onSlotSelected={slotId => setSlotId(slotId)}
                onLocationSelected={location => setLocation(location)}
              />
            </CardBody>
          </Card>
          <Box flex={1} />
          <Stack direction="row" justifyContent="space-between" mt={4}>
            <IconButton
              variant="outline"
              width="48px"
              height="48px"
              aria-label="previous"
              icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><ArrowLeftSmallIcon /></Box>}
              isDisabled={step <= 0}
              onClick={() => {
                // Reset state on step back
                if (workshopId) {
                  setWorkshopId(undefined);
                }
                if (location) {
                  setLocation(undefined);
                }
                if (optionId) {
                  setOptionId(undefined);
                }
                if (slotId) {
                  setSlotId(undefined);
                }

                setStep(step - 1);
              }}
            />
            <Button
              variant="brand"
              rightIcon={<ArrowRightIcon />}
              iconSpacing="auto"
              flex={1}
              isDisabled={(step + 1 > maxSteps) || !validateStep(currentStep)}
              onClick={() => onSubmit(currentStep)}>
              {query.data?.steps?.[step + 1]?.title ?? t("bookingEditor.summary")}
            </Button>
          </Stack>
        </Box>
      )}
      {isSubmitMode && (
        <>
          <Card mb={4}>
            <CardBody>
              <StepLabel step={step + 2} label={t("bookingEditor.summary")} />
              <Text variant="h5Regular" mt={3}>{t("bookingEditor.summaryDescription")}</Text>
            </CardBody>
          </Card>
          <BookingViewer
            booking={query.data}
            mode={mode}
            onStepClick={step => setStep(step)}
          />
          <Stack direction="row" mt={5}>
            <Button
              variant="brand"
              width="100%"
              rightIcon={<CorrectIcon />}
              iconSpacing="auto"
              onClick={() => submitMutation.mutate()}>
              {t("bookingEditor.submit")}
            </Button>
          </Stack>
        </>
      )}
      {isPreviewMode && (
        <>
          <BookingViewer booking={query.data} mode={mode} />
          <Modal variant="bottom" size="xs" isOpen={isActionsModalOpen} onClose={() => setActionsModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <Heading variant="h3Strong" mb={3}>{t("bookingEditor.actions")}</Heading>
                {query.data.status === "Pending" && (
                  <LineCard
                    icon={<ContinueIcon />}
                    pl={0}
                    label={t("bookingEditor.continue")}
                    onClick={() => setMode("workflow")}
                  />
                )}
                {query.data.status === "Billed" && (
                  <LineCard
                    icon={<DownloadIcon />}
                    pl={0}
                    label={t("bookingEditor.downloadInvoice")}
                    onClick={() => downloadMutation.mutate()}
                  />
                )}
                {["Completed", "Billed"].includes(query.data.status) && (
                  <LineCard
                    icon={<ComplaintIcon />}
                    pl={0}
                    label={t("bookingEditor.sendSuggestion")}
                    onClick={() => setComplaintModalOpen(true)}
                  />
                )}
                {["Pending", "Scheduled", "Canceled", "Refused", "Completed", "Billed"].includes(query.data.status) && (
                  <LineCard
                    icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "light" ? "negativeLight" : "negativeDark" } }}>{query.data.status === "Scheduled" ? <CanceledIcon /> : <TrashIcon />}</Box>}
                    pl={0}
                    label={query.data.status === "Scheduled" ? t("bookingEditor.cancel") : t("bookingEditor.delete")}
                    labelProps={{ color: colorMode === "light" ? "negativeLight" : "negativeDark" }}
                    onClick={() => deleteMutation.mutate()}
                  />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal isOpen={isComplaintModalOpen} onClose={() => setComplaintModalOpen(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                {query.data && <ComplaintCreator bookingId={query.data.id} close={() => setComplaintModalOpen(false)} />}
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
      <Modal isOpen={isHelpModalOpen} onClose={() => setHelpModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          {query.data && <Help bookingId={query.data.id} close={() => setHelpModalOpen(false)} />}
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={saveDialog.isOpen}
        leastDestructiveRef={saveDialogCancelRef}
        onClose={saveDialog.onClose}
        size="xs"
        variant="center">
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <Heading variant="h3Strong">{t("bookingEditor.saveDialogHeader")}</Heading>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text variant="h5Regular">{t("bookingEditor.saveDialogDescription")}</Text>
              <Checkbox defaultChecked={saveBooking} mt={3} onChange={event => setSaveBooking(event.target.checked)}>
                {t("bookingEditor.saveDialogCheckbox")}
              </Checkbox>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                variant="brand"
                rightIcon={<ArrowRightIcon />}
                iconSpacing="auto"
                width="100%"
                onClick={onClose}>
                {t(saveBooking ? "bookingEditor.saveDialogConfirmation" : "bookingEditor.saveDialogConfirmationDelete")}
              </Button>
              <Button
                ref={saveDialogCancelRef}
                variant="outline"
                width="100%"
                mt={3}
                justifyContent="start"
                onClick={saveDialog.onClose}>
                {t("bookingEditor.saveDialogCancel")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Page>
  );
}
