import React from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { Page } from "app/shared";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";

interface Props {
  close: () => void;
}

export const Terms: React.FC<Props> = ({ close }) => {
  const { t, i18n } = useTranslation(GLOBAL_NAMESPACE);

  return (
    <Page
      leftIcon={<ArrowLeftIcon />}
      onLeftClick={() => close()}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("terms.header")}</Heading>}
      p={0}>
      <iframe
        src={`https://www.pluxapp.com/legal?tab=terms&lang=${i18n.language.split("-")[0]}`}
        title="Terms"
        width="100%"
        height="100%"
      />
    </Page>
  );
}
