module.exports = {
  production: false,
  api: "https://staging.api.box.waterdog.mobi/v1",
  googleMapsKey: "AIzaSyAKjbW7Sh9dNJBjvaJFipFPO2NGrV330mk",
  amplify: {
    region: "eu-west-1",
    userPoolId: "eu-west-1_kDJc0uKwd",
    userPoolWebClientId: "2t7ib9rk5dmg10h87mqtec0656",
  },
};
