import { StyleFunctionProps, TextProps, defineStyle, } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const fontsStyle = {
  h1Strong: {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "28px"
  },
  h2Strong: {
    fontSize: "19px",
    fontWeight: "600",
    lineHeight: "24px"
  },
  h3Strong: {
    fontSize: "17px",
    fontWeight: "600",
    lineHeight: "19px"
  },
  h4Regular: {
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "20px"
  },
  h4Strong: {
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: "20px"
  },
  h5Regular: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19px"
  },
  h5Strong: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "19px"
  },
  h6Regular: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "16px"
  },
  h6Strong: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "16px"
  },
  h7Strong: {
    fontSize: "10px",
    fontWeight: "600",
    lineHeight: "14px"
  }
};

export const getColor = (props: StyleFunctionProps): string | undefined => {
  const { colorScheme } = props;

  if (!colorScheme) {
    return undefined;
  } else if (colorScheme === "primary") {
    return mode("primaryLight", "primaryDark")(props);
  } else if (colorScheme === "primaryV1") {
    return mode("primaryV1Light", "primaryV1Dark")(props);
  } else if (colorScheme === "positive") {
    return mode("positiveLight", "positiveDark")(props);
  } else if (colorScheme === "brand") {
    return mode("primaryLight", "brandLight")(props);
  } else if (colorScheme === "negative") {
    return mode("negativeLight", "negativeDark")(props);
  }

  throw new Error(`Color scheme ${colorScheme} does not exist`);
}

export const fontVariant = (defaultProps: TextProps) => defineStyle((props) => {
  return {
    ...defaultProps,
    color: getColor(props),
  };
});

export const fontsVariants = {
  h1Strong: fontVariant(fontsStyle.h1Strong),
  h2Strong: fontVariant(fontsStyle.h2Strong),
  h3Strong: fontVariant(fontsStyle.h3Strong),
  h4Regular: fontVariant(fontsStyle.h4Regular),
  h4Strong: fontVariant(fontsStyle.h4Strong),
  h5Regular: fontVariant(fontsStyle.h5Regular),
  h5Strong: fontVariant(fontsStyle.h5Strong),
  h6Regular: fontVariant(fontsStyle.h6Regular),
  h6Strong: fontVariant(fontsStyle.h6Strong),
  h7Strong: fontVariant(fontsStyle.h7Strong)
};

const Text = {
  variants: {...fontsVariants},
  defaultProps: {
    variant: "h4Regular",
    colorScheme: "primary",
  },
};

export default Text;
