import React, { useEffect, useState } from "react";
import { Heading, Text, Button, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid } from "@chakra-ui/react";
import { InfiniteData, useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getNotifications, markAllNotificationsRead } from "../services";
import { AxiosError } from "axios";
import { PagedResponse, ResponseError } from "network";
import { NotificationListItem, NotificationsCounter } from "../models";
import { useTranslation } from "react-i18next";
import { NotificationSettings } from "./NotificationSettings";
import { EmptyResults, NotificationCard, Page } from "app/shared";
import { ReactComponent as GearIcon } from "app/shared/icons/Gear.svg";
import { GLOBAL_NAMESPACE } from "index";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";
import { ReactComponent as BellSelectedIcon } from "app/shared/icons/BellSelected.svg";

interface Props {
  unreadCount: number;
}

export const Notifications: React.FC<Props> = ({ unreadCount }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const queryClient = useQueryClient();
  const [isNotificationSettingsModalOpen, setNotificationSettingsModalOpen] = useState(false);
  const [isScrollBottom, setScrollBottom] = useState(false);

  const query = useInfiniteQuery<PagedResponse<NotificationListItem>, AxiosError<ResponseError>>({
    queryKey: ["notifications"],
    queryFn: (data) => getNotifications(data.pageParam ?? 0),
    getNextPageParam: (lastPage) => lastPage.links.next ? lastPage.meta.page + 1 : undefined
  });
  const mutation = useMutation<void, AxiosError<ResponseError>>({
    mutationFn: () => markAllNotificationsRead(),
    onSuccess: () => {
      queryClient.setQueryData<InfiniteData<PagedResponse<NotificationListItem>>>(["notifications"], (previous) => {
        if (previous) {
          return {
            ...previous,
            pages: previous.pages.map(page => ({
              ...page,
              data: page.data.map(notification => ({ ...notification, read: true }))
            }))
          };
        }

        return previous;
      });
      queryClient.setQueryData<NotificationsCounter>(["notificationCounter"], (previous) => {
        if (previous) {
          return {
            ...previous,
            unread: 0
          };
        }

        return previous;
      });
    }
  });

  const onModalClose = () => {
    setNotificationSettingsModalOpen(false);
  }

  useEffect(() => {
    if (isScrollBottom) {
      if (query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollBottom]);

  const hasResults = !!query.data?.pages?.[0]?.meta?.totalElements;

  return (
    <Page
      center={<Heading variant="h5Strong" colorScheme="brand">{t("notifications.header")}</Heading>}
      rightIcon={<GearIcon />}
      onRightClick={() => setNotificationSettingsModalOpen(true)}
      isMainPage={true}
      onScrollChange={hasResults ? isBottom => setScrollBottom(isBottom) : undefined}>
      {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
      {hasResults ? (
        <>
          {unreadCount > 0 && (
            <Button variant="outline" width="fit-content" mb={3} onClick={() => mutation.mutate()}>
              {t("notifications.markAllRead")}
            </Button>
          )}
          <SimpleGrid spacing={4}>
            {query.data?.pages?.map((group, key) =>
              <React.Fragment key={key}>
                {group.data.map(notification =>
                  <NotificationCard
                    key={notification.id}
                    notification={notification}
                  />
                )}
              </React.Fragment>
            )}
            {query.isFetchingNextPage &&
              <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>
            }
          </SimpleGrid>
        </>
      ) : (
        <EmptyResults
          icon={<BellSelectedIcon />}
          title={t("notifications.emptyResultsTitle")}
          description={t("notifications.emptyResultsDescription")}
        />
      )}
      <Modal isOpen={isNotificationSettingsModalOpen} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <NotificationSettings headerLeftIcon={<ArrowLeftIcon />} onHeaderLeftClick={onModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
