import React, { useState } from "react";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GLOBAL_NAMESPACE } from "index";
import { Page } from "app/shared";
import { VehiclesList } from "./VehiclesList";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";

interface Props {
  close: () => void;
}

export const ManageVehicles: React.FC<Props> = ({ close }) => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const [isScrollBottom, setScrollBottom] = useState(false);

  return (
    <Page
      leftIcon={<ArrowLeftIcon />}
      onLeftClick={close}
      center={<Heading variant="h5Strong" colorScheme="brand">{t("manageVehicles.header")}</Heading>}
      onScrollChange={isBottom => setScrollBottom(isBottom)}>
      <VehiclesList isScrollBottom={isScrollBottom} />
    </Page>
  );
}
