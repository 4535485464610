import { BoxProps, Box, useColorMode, Text } from "@chakra-ui/react";
import { BookingStatus } from "app/bookings/models";
import React from "react";
import { ReactComponent as PendingIcon } from "app/shared/icons/Pending.svg";
import { ReactComponent as ScheduledIcon } from "app/shared/icons/Scheduled.svg";
import { ReactComponent as CanceledIcon } from "app/shared/icons/Canceled.svg";
import { ReactComponent as RefusedIcon } from "app/shared/icons/Refused.svg";
import { ReactComponent as CompletedIcon } from "app/shared/icons/Completed.svg";
import { ReactComponent as BilledIcon } from "app/shared/icons/Billed.svg";

export const statusMap = [
  { name: "Pending", icon: PendingIcon, label: "shared.bookingStatus.pending" },
  { name: "Scheduled", icon: ScheduledIcon, label: "shared.bookingStatus.scheduled" },
  { name: "Canceled", icon: CanceledIcon, label: "shared.bookingStatus.canceled" },
  { name: "Refused", icon: RefusedIcon, label: "shared.bookingStatus.refused" },
  { name: "Completed", icon: CompletedIcon, label: "shared.bookingStatus.completed" },
  { name: "Billed", icon: BilledIcon, label: "shared.bookingStatus.billed" },
  { name: "Deleted", icon: CanceledIcon, label: "shared.bookingStatus.deleted" },
];

interface Props extends BoxProps {
  status: BookingStatus;
}

export const BookingStatusIcon: React.FC<Props> = ({ status, ...props }) => {
  const { colorMode } = useColorMode();
  const selectedStatus = statusMap.find(it => it.name === status);

  return selectedStatus ? (
    <Box
      as="span"
      mr={2}
      sx={{ "& svg > path": { fill: colorMode === "dark" && status === "Pending" ? "primaryDark" : undefined } }}
      {...props}>
      {<selectedStatus.icon />}
    </Box>
  ) : <Text>{status}</Text>;
}
