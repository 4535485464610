import React, { useEffect, useState } from "react";
import { Heading, Stack, Text, Modal, ModalOverlay, ModalContent, ModalBody, Button, IconButton, SimpleGrid, useColorMode, Box } from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getBookings } from "../services";
import { AxiosError } from "axios";
import { PagedResponse, ResponseError } from "network";
import { BookingListItem } from "../models";
import { useTranslation } from "react-i18next";
import { BookingEditor, BookingMode } from "./BookingEditor";
import { useSearchParams } from "react-router-dom";
import { BookingFilters, BookingsFilter } from "./BookingsFilter";
import { ReactComponent as CloseIcon } from "app/shared/icons/CloseCircle.svg";
import { ReactComponent as FilterIcon } from "app/shared/icons/Filter.svg";
import { BookingCard, EmptyResults, Page, Scrollable } from "app/shared";
import { GLOBAL_NAMESPACE } from "index";
import { servicesMap } from "app/shared/ServiceCard";
import { ReactComponent as CalendarSelectedIcon } from "app/shared/icons/CalendarSelected.svg";

export const Bookings: React.FC = () => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const { colorMode } = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<BookingFilters | undefined>();
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>(searchParams.get("bookingId") ?? undefined);
  const [bookingMode, setBookingMode] = useState<BookingMode>((searchParams.get("mode") ?? "preview") as BookingMode);
  const [isScrollBottom, setScrollBottom] = useState(false);

  const query = useInfiniteQuery<PagedResponse<BookingListItem>, AxiosError<ResponseError>>({
    queryKey: ["bookings", filters],
    queryFn: (data) => getBookings({
      page: data.pageParam ?? 0,
      startDate: filters?.startDate ? new Date(filters.startDate).toISOString() : undefined,
      endDate: filters?.endDate ? new Date(filters.endDate).toISOString() : undefined,
      plateNumber: filters?.plateNumber,
      status: filters?.status,
      serviceId: filters?.serviceId
    }),
    getNextPageParam: (lastPage) => lastPage.links.next ? lastPage.meta.page + 1 : undefined
  });

  const onModalClose = () => {
    setSelectedBookingId(undefined);
    setBookingMode("preview");
    setSearchParams({ tab: "bookings" });
  }

  useEffect(() => {
    if (isScrollBottom) {
      if (query.hasNextPage && !query.isFetchingNextPage) {
        query.fetchNextPage();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollBottom]);

  const hasResults = !!query.data?.pages?.[0]?.meta?.totalElements;

  return (
    <Page
      center={<Heading variant="h5Strong" colorScheme="brand">{t("bookings.header")}</Heading>}
      rightIcon={hasResults ? <FilterIcon /> : undefined}
      onRightClick={() => setFilterModalOpen(true)}
      isMainPage={true}
      onScrollChange={hasResults ? isBottom => setScrollBottom(isBottom) : undefined}>
      <Stack direction="column" height="100%">
        {query.isError && <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.error", { message: query.error.response?.data?.title ?? query.error.message })}</Text>}
        {filters && (
          <Scrollable mb={3}>
            <IconButton
              variant="solid"
              aria-label="Filter"
              icon={<Box as="span" sx={{ "& svg > path": { fill: colorMode === "dark" ? "primaryDark" : undefined } }}><CloseIcon /></Box>}
              onClick={() => setFilters(undefined)}
            />
            {filters?.plateNumber &&
              <Button variant="solid" ml={2} onClick={() => setFilterModalOpen(true)}>
                <Box>
                  <Text variant="h7Strong" colorScheme="primaryV1">{t("bookingsFilter.plateNumber")}</Text>
                  <Text variant="h5Strong">{filters.plateNumber}</Text>
                </Box>
              </Button>
            }
            {filters?.serviceId &&
              <Button variant="solid" ml={2} onClick={() => setFilterModalOpen(true)}>
                <Box>
                  <Text variant="h7Strong" colorScheme="primaryV1">{t("bookingsFilter.service")}</Text>
                  <Text variant="h5Strong">{t(servicesMap.find(it => it.names.includes(filters.serviceId!))!.label!)}</Text>
                </Box>
              </Button>
            }
            {filters?.status &&
              <Button variant="solid" ml={2} onClick={() => setFilterModalOpen(true)}>
                <Box>
                  <Text variant="h7Strong" colorScheme="primaryV1">{t("bookingsFilter.status")}</Text>
                  <Text variant="h5Strong">{filters.status}</Text>
                </Box>
              </Button>
            }
            {filters?.startDate &&
              <Button variant="solid" ml={2} onClick={() => setFilterModalOpen(true)}>
                <Box>
                  <Text variant="h7Strong" colorScheme="primaryV1">{t("bookingsFilter.startDate")}</Text>
                  <Text variant="h5Strong">{filters.startDate}</Text>
                </Box>
              </Button>
            }
            {filters?.endDate &&
              <Button variant="solid" ml={2} onClick={() => setFilterModalOpen(true)}>
                <Box>
                  <Text variant="h7Strong" colorScheme="primaryV1">{t("bookingsFilter.endDate")}</Text>
                  <Text variant="h5Strong">{filters.endDate}</Text>
                </Box>
              </Button>
            }
          </Scrollable>
        )}
        {hasResults ? (
          <SimpleGrid spacing={4}>
            {query.data?.pages?.map((group, key) =>
              <React.Fragment key={key}>
                {group.data?.map(booking =>
                  <BookingCard
                    key={booking.id}
                    booking={booking}
                    cursor="pointer"
                    onClick={() => {
                      setSelectedBookingId(booking.id);
                      setBookingMode("preview");
                      setSearchParams({ tab: "bookings", bookingId: booking.id });
                    }}
                  />
                )}
              </React.Fragment>
            )}
            {query.isFetchingNextPage &&
              <Text variant="h5Regular" colorScheme="primaryV1" textAlign="center" my={3}>{t("shared.network.loading")}</Text>
            }
          </SimpleGrid>
        ) : (
          <EmptyResults
            icon={<CalendarSelectedIcon />}
            title={filters ? t("bookings.emptyResultsFiltersTitle") : t("bookings.emptyResultsTitle")}
            description={filters ? t("bookings.emptyResultsFiltersDescription") : t("bookings.emptyResultsDescription")}
          />
        )}
      </Stack>
      <Modal isOpen={isFilterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <BookingsFilter
              defaultFilters={filters}
              close={() => setFilterModalOpen(false)}
              onFiltersChange={filters => {
                setFilters(filters);
                setFilterModalOpen(false);
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={selectedBookingId !== undefined} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {selectedBookingId && <BookingEditor bookingId={selectedBookingId} close={onModalClose} defaultMode={bookingMode} />}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
