import React, { useState } from "react";
import { Heading, Modal, ModalBody, ModalContent, ModalOverlay, TabPanel, TabPanels, Tabs, Card, CardBody } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { NotificationSettings } from "./NotificationSettings";
import { useSearchParams } from "react-router-dom";
import { ComplaintCreator } from "./ComplaintCreator";
import { GLOBAL_NAMESPACE } from "index";
import { Page, LineCard } from "app/shared";
import { ReactComponent as CarIcon } from "app/shared/icons/Car.svg";
import { ReactComponent as GearSelectedIcon } from "app/shared/icons/GearSelected.svg";
import { ReactComponent as ComplaintIcon } from "app/shared/icons/Complaint.svg";
import { ReactComponent as HelpIcon } from "app/shared/icons/Help.svg";
import { ReactComponent as TermsIcon } from "app/shared/icons/Terms.svg";
import { ReactComponent as ArrowLeftIcon } from "app/shared/icons/ArrowLeft.svg";
import { Help } from "./Help";
import { Terms } from "./Terms";
import { ManageVehicles } from "./ManageVehicles";

export const Settings: React.FC = () => {
  const { t } = useTranslation(GLOBAL_NAMESPACE);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedSection, setSelectedSection] = useState<string | undefined>(searchParams.get("section") ?? undefined);
  const sectionsDefinition = [
    { index: 0, name: "vehicles", icon: <CarIcon /> },
    { index: 1, name: "notifications", icon: <GearSelectedIcon /> },
    { index: 2, name: "complaints", icon: <ComplaintIcon /> },
    { index: 3, name: "help", icon: <HelpIcon /> },
    { index: 4, name: "terms", icon: <TermsIcon /> }
  ];

  const onSectionSelected = (name: string) => {
    setSelectedSection(name);
    setSearchParams({ tab: "settings", section: name });
  }
  const onModalClose = () => {
    setSelectedSection(undefined);
    setSearchParams({ tab: "settings" });
  }

  return (
    <Page center={<Heading variant="h5Strong" colorScheme="brand">{t("settings.header")}</Heading>} isMainPage={true}>
      <Card>
        <CardBody p={0}>
          {sectionsDefinition.slice(0, 3).map(tab => (
            <LineCard
              key={tab.index}
              icon={tab.icon}
              label={t(`settings.${tab.name}`)}
              onClick={() => onSectionSelected(tab.name)}
            />
          ))}
        </CardBody>
      </Card>
      <Card mt={5}>
        <CardBody p={0}>
          {sectionsDefinition.slice(3, 5).map(tab => (
            <LineCard
              key={tab.index}
              icon={tab.icon}
              label={t(`settings.${tab.name}`)}
              onClick={() => onSectionSelected(tab.name)}
            />
          ))}
        </CardBody>
      </Card>
      <Modal isOpen={selectedSection !== undefined} onClose={onModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Tabs variant="fullHeight" isLazy index={sectionsDefinition.find(section => section.name === selectedSection)?.index ?? 0}>
              <TabPanels>
                <TabPanel>
                  <ManageVehicles close={onModalClose} />
                </TabPanel>
                <TabPanel>
                  <NotificationSettings headerLeftIcon={<ArrowLeftIcon />} onHeaderLeftClick={onModalClose} />
                </TabPanel>
                <TabPanel>
                  <ComplaintCreator close={onModalClose} />
                </TabPanel>
                <TabPanel>
                  <Help close={onModalClose} />
                </TabPanel>
                <TabPanel>
                  <Terms close={onModalClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Page>
  );
}
